import React, {useEffect, useState} from 'react';
import AuthService from "./service/AuthService";
import {BrowserRouter, HashRouter} from "react-router-dom";
import CoupleSummaryContext from "./context/CoupleSummaryContextProvider";
import {Box, CircularProgress, createTheme, PaletteMode, ThemeProvider} from "@mui/material";
import {grey} from "@mui/material/colors";
import "./res/font/font.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import SiteRouter from "./SiteRouter";
import {LocalizationProvider} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import {SnackbarProvider} from "notistack";
import MessagingHandler from './components/MessagingHandler';
import SnowfallOverlayV1 from "./components/SnowfallOverlayV1";
import SnowfallOverlayV2 from "./components/SnowfallOverlayV2";

export const ColorModeContext = React.createContext({
    setColorMode: (colorMode: PaletteMode) => {
    }
});

function App() {
    const [isFirebaseInitialized, setFirebaseInitialized] = useState(false);
    useEffect(() => {
        AuthService.onAuthStateChanged((user) => {
            console.log("user", user);
            setFirebaseInitialized(true);
        });
    }, []);

    const [mode, setMode] = useState<PaletteMode>('dark');
    const colorMode = React.useMemo(
        () => ({
            // The dark mode switch would invoke this method
            setColorMode: (colorMode: PaletteMode) => {
                setMode(colorMode);
            },
        }),
        [],
    );

    const getDesignTokens = (mode: PaletteMode) => ({
        palette: {
            mode,
            typography: {
                fontFamily: [
                    'Roboto',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
                fontSize: 18,
            },


            ...(mode === "light") ? {
                background: {
                    default: "#f2fbff",
                },
                text: {
                    primary: grey[800]
                }
            } : {
                background: {
                    default: "#001828"
                },
                primary: {
                    main: "#FFFFFF",
                    light: '#35969b',
                },
            }

        },
    });

    // Update the theme only if the mode changes
    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                backgroundColor: 'background.default',
                                color: 'text.primary'
                            }}
                        >
                            {
                                isFirebaseInitialized ? (
                                    <BrowserRouter>
                                        <CoupleSummaryContext>
                                            <MessagingHandler>
                                                <SiteRouter/>
                                            </MessagingHandler>
                                        </CoupleSummaryContext>
                                    </BrowserRouter>
                                ) : (
                                    <CircularProgress/>
                                )
                            }

                            <Box
                                id="snowfallContainer"
                                sx={{
                                    position: "fixed",
                                    left: 0,
                                    top: 0,
                                    width: "100%",
                                    height: "100%",
                                    overflow: "hidden",
                                    zIndex: "5000",
                                    pointerEvents: "none"
                                }}
                            >
                                <SnowfallOverlayV2/>
                            </Box>
                        </Box>
                    </SnackbarProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </LocalizationProvider>
    );
}

export default App;
