import {Box} from "@mui/material";
import styles from "./index.module.scss";
import React from "react";

type Props = {
    color: string
}

export default function RectangularLoadingSpinner(props: Props) {
    return (
        <Box
            className={styles.box}
            sx={{
                width: "100%",
                height: "100%",
                "&:before": {
                    background: props.color
                }
            }}
        />
    );
}
