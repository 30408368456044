import _ from "lodash";

export function replaceAllText(stringToBeReplaced: string, data: Array<{ key: string, value: string }>): string {
    let text = stringToBeReplaced || "";
    data && data.forEach(item => {
        text = _.replace(text, new RegExp(item.key, "g"), item.value || '')
    });
    return text;
}

export function getUniqueId() {
    return Date.now() + '-' + Math.round(Math.random() * 1E9)
}
