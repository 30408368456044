import React, {useContext, useEffect, useState} from "react";
import {ColorModeContext} from "../../App";
import {Box, Button, Checkbox, FormControlLabel, Grid, TextField} from "@mui/material";
import Page from "../../components/Page";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useForm} from "react-hook-form";
import AuthService from "../../service/AuthService";
import {CoupleSummaryContext} from "../../context/CoupleSummaryContextProvider";
import LocalStorageUtil from "../../util/LocalStorageUtil";

const LOCAL_STORAGE_EMAIL_KEY = "email";

type LoginFormData = {
    email: string,
    password: string
};

export default function LoginPage() {
    const colorMode = useContext(ColorModeContext);
    const coupleSummaryContext = useContext(CoupleSummaryContext);

    const rememberedEmail = LocalStorageUtil.getValue(LOCAL_STORAGE_EMAIL_KEY);

    const [uiStep, setUiStep] = useState(0);
    const [isRememberEmail, setIsRememberEmail] = useState(!!LocalStorageUtil.getValue(LOCAL_STORAGE_EMAIL_KEY));
    const {register, handleSubmit, watch, formState: {errors}} = useForm<LoginFormData>({
        defaultValues: {
            email: rememberedEmail || ""
        }
    });

    useEffect(() => {
        colorMode.setColorMode("dark");
    }, []);

    const greetingHeaderStyle = {
        margin: "0 8px",
        opacity: uiStep > 0 ? 1 : 0
    };

    useEffect(() => {
        if (uiStep === 0) {
            setTimeout(() => setUiStep(1), 1);
        } else if (uiStep === 1) {
            setTimeout(() => setUiStep(2), 1500);
        } else if (uiStep === 2) {
            setTimeout(() => setUiStep(3), 1);
        } else if (uiStep === 3) {
            setTimeout(() => setUiStep(4), 1);
        }
    }, [uiStep])

    const onLogin = handleSubmit((data) => {
        AuthService.signInWithEmailAndPassword(data.email, data.password, (isSuccess) => {
            if (isSuccess) {
                if (isRememberEmail) {
                    LocalStorageUtil.setValue(LOCAL_STORAGE_EMAIL_KEY, data.email);
                } else {
                    LocalStorageUtil.removeValue(LOCAL_STORAGE_EMAIL_KEY);
                }
                coupleSummaryContext?.api.loadSummary();
            }
        })
    });

    return (
        <Page
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                "& > *": {
                    transition: "all 0.5s",
                    transitionTimingFunction: "ease"
                },
                "& > h1": {
                    fontSize: {
                        xs: "24px",
                        sm: null
                    }
                }
            }}
        >
            <h1 style={{
                ...greetingHeaderStyle,
                position: "relative",
                "top": "-20px",
                fontSize: "min(10vw, 48px)",
                "transform": "rotate(-5deg)"
            }}>こんにちは！</h1>
            <h1 style={{
                ...greetingHeaderStyle,
                fontSize: "min(8vw, 32px)",
            }}>請先登入</h1>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: {
                        xs: "100%",
                        md: "600px"
                    },
                    height: uiStep <= 2 ? 0 : "250px",
                    margin: "16px",
                    padding: "24px",
                    background: "#0e2636",
                    border: "2px solid #35969b",
                    borderRadius: "8px",
                    opacity: uiStep <= 3 ? 0 : 1
                }}
            >
                <form onSubmit={onLogin}>
                    <TextField
                        label="電郵地址"
                        type="email"
                        variant="standard"
                        color={errors.email ? "error" : "primary"}
                        style={{
                            width: "100%",
                            margin: "8px 0"
                        }}
                        {...register("email", {required: true})}
                    />

                    <TextField
                        label="密碼"
                        type="password"
                        variant="standard"
                        color={errors.password ? "error" : "primary"}
                        style={{
                            width: "100%",
                            margin: "8px 0"
                        }}
                        {...register("password", {required: true})}
                    />

                    <Grid
                        container
                        sx={{
                            width: "100%",
                            margin: "16px 0"
                        }}
                    >
                        <Grid item xs={12} md={5}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isRememberEmail}
                                        onChange={(e) => setIsRememberEmail(e.target.checked)}
                                    />
                                }
                                label="記住電郵地址"
                                sx={{marginRight: 0}}
                            />
                        </Grid>
                        <Grid item xs={12} md={7}
                              sx={{
                                  textAlign: {
                                      xs: "left",
                                      md: "right"
                                  }
                              }}
                        >
                            <Button
                                variant="outlined"
                                type="submit"
                                size="large"
                                endIcon={<NavigateNextIcon fontSize="inherit"/>}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Page>
    );
}
