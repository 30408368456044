import {getUniqueId} from "../util/stringUtils";
import Api from "../bundle/Api";
import {MediaInfo} from "../data/media";
import {ApiError} from "../data/error";

export type MediaUploadId = string;
export type MediaStatusUpdateCallback = (mediaStatus: MediaStatus) => void;

export type MediaStatus = {
    id: MediaUploadId,
    file: File | null,
    uploadStatus: "pending" | "uploading" | "success" | "failed" | "cancelled",
    statusUpdateCallback: MediaStatusUpdateCallback,
    response?: MediaInfo | ApiError,
    progressCallback?: (percentage: number) => void,
    cancel?: () => void
}

const MAX_CONCURRENT_UPLOAD = 3;

export default class DiaryMediaUploadService {
    static medias: {[key: MediaUploadId]: MediaStatus} = {};
    static pendingMediaUploadIds: MediaUploadId[] = [];
    static uploadingMediaUploadIds: MediaUploadId[] = [];

    static append(file: File, callback: MediaStatusUpdateCallback): MediaStatus {
        const id = getUniqueId();
        const status: MediaStatus = {
            id,
            file,
            uploadStatus: "pending",
            statusUpdateCallback: callback
        };

        DiaryMediaUploadService.medias[id] = status;
        DiaryMediaUploadService.pendingMediaUploadIds.push(id);

        DiaryMediaUploadService.checkStartUpload();

        return status;
    }

    static attachProgressCallback(mediaId: MediaUploadId, callback: (percentage: number) => void) {
        const media = DiaryMediaUploadService.medias[mediaId];
        if (!media) return false;
        DiaryMediaUploadService.medias[mediaId].progressCallback = callback;
        return true;
    }

    static removeProgressCallback(mediaId: MediaUploadId) {
        const media = DiaryMediaUploadService.medias[mediaId];
        if (!media) return;
        DiaryMediaUploadService.medias[mediaId].progressCallback = undefined;
    }

    static cancel(mediaId: MediaUploadId): boolean {
        const media = DiaryMediaUploadService.medias[mediaId];

        if (!media) {
            return false;
        }

        if (media.uploadStatus === "pending") {
            DiaryMediaUploadService.pendingMediaUploadIds = DiaryMediaUploadService.pendingMediaUploadIds.filter((pendingId) => pendingId === mediaId);
            media.uploadStatus = "cancelled";
            delete DiaryMediaUploadService.medias[mediaId];
            media.statusUpdateCallback(media);
        } else if (media.uploadStatus === "uploading") {
            media.cancel!();
            DiaryMediaUploadService.uploadingMediaUploadIds = DiaryMediaUploadService.uploadingMediaUploadIds.filter((pendingId) => pendingId === mediaId);
            media.uploadStatus = "cancelled";
            delete DiaryMediaUploadService.medias[mediaId];
            media.statusUpdateCallback(media);
        }

        return true;
    }

    private static checkStartUpload() {
        if (
            DiaryMediaUploadService.pendingMediaUploadIds.length === 0 ||
            DiaryMediaUploadService.uploadingMediaUploadIds.length === MAX_CONCURRENT_UPLOAD
        )
            return;

        while (
            DiaryMediaUploadService.pendingMediaUploadIds.length > 0 &&
            DiaryMediaUploadService.uploadingMediaUploadIds.length < MAX_CONCURRENT_UPLOAD
        ) {
            const mediaId = DiaryMediaUploadService.pendingMediaUploadIds.shift();
            DiaryMediaUploadService.uploadingMediaUploadIds.push(mediaId!);
            const updatedMedia: MediaStatus = {
                ...DiaryMediaUploadService.medias[mediaId!],
                uploadStatus: "uploading"
            };
            DiaryMediaUploadService.medias[mediaId!] = updatedMedia;

            DiaryMediaUploadService.doUploadMedia(mediaId!);
            updatedMedia.statusUpdateCallback(updatedMedia);
        }
    }

    private static doUploadMedia(id: MediaUploadId) {
        const media = DiaryMediaUploadService.medias[id];
        const result = Api.adapters.diary.uploadCreateDiaryMedia(media.file!, (percentage) => {media.progressCallback && media.progressCallback(percentage)});
        DiaryMediaUploadService.medias[id].cancel = result.cancel;

        result.promise
            .then(apiResponse => {
                let updatedMedia: MediaStatus;
                if (apiResponse.status === 200) {
                    updatedMedia = {
                        ...media,
                        uploadStatus: "success",
                        response: apiResponse.data as MediaInfo
                    };
                } else {
                    updatedMedia = {
                        ...media,
                        uploadStatus: "failed",
                        response: apiResponse.data as ApiError
                    };
                }

                DiaryMediaUploadService.uploadingMediaUploadIds = DiaryMediaUploadService.uploadingMediaUploadIds.filter(
                    (pendingId) => pendingId === id
                );

                delete DiaryMediaUploadService.medias[id];

                updatedMedia.statusUpdateCallback(updatedMedia);
                DiaryMediaUploadService.checkStartUpload();
            })
            .catch(error => {
                console.error(error);
                const updatedMedia: MediaStatus = {
                    ...media,
                    uploadStatus: "failed"
                };

                DiaryMediaUploadService.uploadingMediaUploadIds = DiaryMediaUploadService.uploadingMediaUploadIds.filter(
                    (pendingId) => pendingId === id
                );

                delete DiaryMediaUploadService.medias[id];

                updatedMedia.statusUpdateCallback(updatedMedia);
                DiaryMediaUploadService.checkStartUpload();
            })
    }
}
