import React, {useEffect, useRef, useState} from "react";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {Box, Grid} from "@mui/material";
import {AudioApi} from "../../../data/lyricsPlayer";
import PlayerProgressBar from "./PlayerProgressBar";

type Props = {
    id?: string,
    title?: string,
    artist?: string,
    albumImgUrl?: string,
    audioApi: AudioApi,
    isPlaying: boolean,
    nextMusic: () => void,
    prevMusic: () => void
}

export default function PlayerControl(props: Props) {
    const progressUpdateInterval = useRef<undefined | number>(undefined);
    const [progress, setProgress] = useState<undefined | number>(undefined);

    const updateProgress = () => {
        const progressPercent = props.audioApi.getCurrentTime() / props.audioApi.getDuration();
        setProgress(progressPercent);
    };

    useEffect(() => {
        if (progressUpdateInterval.current !== undefined && !props.isPlaying) {
            window.clearInterval(progressUpdateInterval.current);
            progressUpdateInterval.current = undefined;
        } else if (progressUpdateInterval.current === undefined && props.isPlaying) {
            progressUpdateInterval.current = window.setInterval(updateProgress, 30);
        }
    }, [props.isPlaying])

    useEffect(() => {
        if (progressUpdateInterval.current !== undefined) {
            setProgress(0);
        }
    }, [props.id])

    const controlButtonSx = {
        margin: "0 8px",
        cursor: "pointer",
        fontSize: {
            xs: "24px",
            md: "32px"
        },
        ":hover": {
            "color": (theme: any) => theme.palette.primary.main
        }
    }

    return (
        <Box
            sx={{
                width: {
                    xs: "100%",
                    md: "40%"
                },
                minWidth: "400px",
                height: "50%",
                display: "flex",
                flexDirection: "row",
                marginLeft: {
                    xs: "4px",
                    md: "16px"
                }
            }}
        >

            <Box
                sx={{
                    position: "absolute",
                    width: "100%",
                    top: 0,
                    left: 0,
                    padding: "0 30px 0 20px",
                    background: "white"
                }}
            >
                <PlayerProgressBar
                    audioId={props.id}
                    collapsedHeight={8}
                    expendedHeight={16}
                    progress={progress === undefined ? 0 : progress}
                    audioApi={{
                        ...props.audioApi,
                        setCurrentTime: (currentTime) => {
                            props.audioApi.setCurrentTime(currentTime);
                            updateProgress();
                        }
                    }}
                    onSeek={() => {
                    }}/>
            </Box>

            <Box
                sx={{
                    position: "relative",
                    display: {
                        xs: "none",
                        sm: "block"
                    },
                    height: "100%",
                    width: {
                        xs: "60px",
                        md: "100px"
                    },
                    backgroundImage: `url('${props.albumImgUrl}')`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat"
                }}
            />

            <Grid
                container
                sx={{
                    margin: "0 16px",
                    "& h2": {
                        fontSize: {
                            xs: "18px",
                            md: "24px"
                        }
                    },
                    "& h3": {
                        fontSize: {
                            xs: "12px",
                            md: "18.72px"
                        }
                    }
                }}
            >
                <Grid
                    item
                    xs={6}
                    md={12}
                >
                    <h2 style={{margin: 0, color: "#000"}}>{props.title}</h2>
                    <h3 style={{margin: 0}}>{props.artist}</h3>
                </Grid>

                <Box
                    sx={{
                        position: {
                            xs: "absolute",
                            md: "static"
                        },
                        right: {
                            xs: "32px",
                            md: undefined
                        },
                        top: {
                            xs: "56px",
                            md: undefined
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: "flex"
                        }}
                    >
                        <SkipPreviousIcon
                            sx={controlButtonSx}
                            onClick={props.prevMusic}
                        />

                        {
                            props.isPlaying ? (
                                <PauseIcon
                                    sx={controlButtonSx}
                                    onClick={() => {
                                        props.audioApi.pause();
                                    }}
                                />
                            ) : (
                                <PlayArrowIcon
                                    sx={controlButtonSx}
                                    onClick={() => {
                                        props.audioApi.play();
                                    }}
                                />
                            )
                        }

                        <SkipNextIcon
                            sx={controlButtonSx}
                            fontSize="large"
                            onClick={props.nextMusic}
                        />
                    </Box>
                </Box>

            </Grid>
        </Box>
    )
}
