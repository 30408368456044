import UrlBuilder from "./UrlBuilder";
import Api from "../bundle/Api";

const DiaryApiUrl = {
    urls: {
        getDiaryList: "/diary",
        getDiaryDetail: "/diary/{{diary_id}}",
        uploadCreateDiaryMedia: "/diary/create/media/upload",
        removeCreateDiaryMedia: "/diary/create/media/remove/{{media_id}}",
        create: "/diary/create",
        edit: "/diary/edit/{{diary_id}}",
        createComment: "/diary/{{diary_id}}/comment"
    },
    builders: {
        getDiaryList: () => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.getDiaryList).build();
        },

        getDiaryDetail: (diaryId: string) => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.getDiaryDetail)
                .withSubstitutions([
                    {key: "{{diary_id}}", value: diaryId}
                ])
                .build();
        },

        uploadCreateDiaryMedia: () => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.uploadCreateDiaryMedia).build();
        },

        removeCreateDiaryMedia: (mediaId: string) => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.removeCreateDiaryMedia)
                .withSubstitutions([
                    {key: "{{media_id}}", value: mediaId}
                ])
                .build();
        },

        create: () => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.create).build();
        },

        edit: (diaryId: string) => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.edit)
                .withSubstitutions([
                    {key: "{{diary_id}}", value: diaryId}
                ])
                .build();
        },

        createComment: (diaryId: string) => {
            return new UrlBuilder(Api.servers.core + Api.urls.diary.createComment)
                .withSubstitutions([
                    {key: "{{diary_id}}", value: diaryId}
                ])
                .build();
        }
    }
}

export default DiaryApiUrl;
