import Lyric from "lrc-file-parser";
import React, {useEffect, useRef, useState} from "react";
import PlayerPanel from "./PlayerPanel";
import {guess} from "web-audio-beat-detector";
import {Box} from "@mui/material";
import ExpendedLyrics from "./ExpendedLyrics";

export const LyricsPlayerContext = React.createContext({
    isPlaying: false,
    musicInfo: undefined,
    estimateData: {
        bpm: undefined,
        offset: undefined,
        tempo: undefined
    }
});



export default function LyricsPlayer(props) {
    const [rawPrimaryLyc, setRawPrimaryLyc] = useState(undefined);
    const [rawSecondaryLyc, setRawSecondaryLyc] = useState(undefined);
    const [calculatedLyc, setCalculatedLyc] = useState(undefined);
    const [currentLycIndex, setCurrentLycIndex] = useState(undefined);
    const [isPlayerPlaying, setIsPlayerPlaying] = useState(true);
    const [estimateMusicData, setEstimateMusicData] = useState(undefined);
    const [isExpendedLyrics, setIsExpendedLyrics] = useState(false);

    const lrcPlayer = useRef();
    const audio = useRef();

    const lrcPlayerApi = {
        play: (time) => lrcPlayer.current && lrcPlayer.current.play(time),
        pause: () => lrcPlayer.current && lrcPlayer.current.pause()
    }

    const audioApi = {
        getAudio: () => audio.current,
        isPlaying: () => audio.current && !audio.current.paused && isPlayerPlaying,
        setCurrentTime: (currentTime) => {
            if (!audio.current) return;
            audio.current.currentTime = currentTime;
        },
        getCurrentTime: () => audio.current && audio.current.currentTime,
        getDuration: () => audio.current && audio.current.duration,
        play: () => {
            setIsPlayerPlaying(true);
            audio.current.play();
        },
        pause: () => {
            setIsPlayerPlaying(false);
            audio.current.pause();
        },
        addLoadedDataListener: (callback) => audio.current && audio.current.addEventListener("loadeddata", callback),
        removeLoadedDataListener: (callback) => audio.current && audio.current.removeEventListener("loadeddata", callback)
    }

    const audioEventListeners = {
        onAudioPlay: () => {
            lrcPlayerApi.play(audio.current.currentTime * 1000);
        },
        onAudioPlaying: () => {
            lrcPlayerApi.play(audio.current.currentTime * 1000);
        },
        onAudioPause: () => {
            lrcPlayerApi.pause();
        },
        onAudioSeeking: () => {
            lrcPlayerApi.play(audio.current.currentTime);
        },
        onAudioEnded: () => {
            props.nextMusic && props.nextMusic();
        },
        onAudioLoadedData: () => {
            if (audio.current.readyState >= 2 && isPlayerPlaying) {
                audio.current.play();
            }
        }
    }

    useEffect(() => {
        if (lrcPlayer.current) {
            lrcPlayer.current.pause();
        }

        if (audio.current) {
            audio.current.pause();
            audio.current.currentTime = 0;
            audio.current.removeEventListener("play", audioEventListeners.onAudioPlay);
            audio.current.removeEventListener("playing", audioEventListeners.onAudioPlaying);
            audio.current.removeEventListener("pause", audioEventListeners.onAudioPause);
            audio.current.removeEventListener("seeking", audioEventListeners.onAudioSeeking);
            audio.current.removeEventListener("ended", audioEventListeners.onAudioEnded);
            audio.current.removeEventListener("loadeddata", audioEventListeners.onAudioLoadedData);
        }

        setRawPrimaryLyc(undefined);
        setRawSecondaryLyc(undefined);
        setCalculatedLyc(undefined);
        setCurrentLycIndex(undefined);
        setEstimateMusicData(undefined);

        // Initialize the LYC player
        lrcPlayer.current = new Lyric({
            onPlay: function (line, text) { // Listening play event
                setCurrentLycIndex(line)
            },
            onSetLyric: function (lines) { // listening lyrics seting event
                setCalculatedLyc(lines) // lines is array of all lyric text
            },
            isRemoveBlankLine: false // is remove blank line, default is true
        })

        if (!props.currentMusic) return;

        // Initialize the audio
        const newAudio = new Audio(props.currentMusic.url);
        newAudio.addEventListener("play", audioEventListeners.onAudioPlay);
        newAudio.addEventListener("playing", audioEventListeners.onAudioPlaying);
        newAudio.addEventListener("pause", audioEventListeners.onAudioPause);
        newAudio.addEventListener("seeking", audioEventListeners.onAudioSeeking);
        newAudio.addEventListener("ended", audioEventListeners.onAudioEnded);
        newAudio.addEventListener("loadeddata", audioEventListeners.onAudioLoadedData);
        audio.current = newAudio;

        fetch(props.currentMusic.url)
            .then(r => r.arrayBuffer())
            .then(arrayBuffer => {
                const audioCtx = new AudioContext();
                return audioCtx.decodeAudioData(arrayBuffer);
            })
            .then(audioBuffer => {
                return guess(audioBuffer)
            })
            .then(estimate => {
                if (estimate.bpm >= 170) {
                    estimate.bpm /= 2;
                    estimate.tempo /= 2;
                }
                setEstimateMusicData(estimate);
            })
            .catch(reason => {
                setEstimateMusicData(undefined);
            });

        if (props.currentMusic.audioInfo && props.currentMusic.audioInfo.primaryLyc) {
            fetch(props.currentMusic.audioInfo.primaryLyc.url)
                .then(
                    async (res) => {
                        const lyc = await res.text();
                        setRawPrimaryLyc(lyc);
                    },
                    (error) => {
                        setRawPrimaryLyc(false);
                    }
                );
        }

        if (props.currentMusic.audioInfo && props.currentMusic.audioInfo.secondaryLyc) {
            fetch(props.currentMusic.audioInfo.secondaryLyc.url)
                .then(
                    async (res) => {
                        const lyc = await res.text();
                        setRawSecondaryLyc(lyc);
                    },
                    (error) => {
                        setRawSecondaryLyc(false);
                    }
                );
        } else {
            setRawSecondaryLyc(false);
        }

        audio.current.currentTime = 0;
    }, [props.currentMusic])

    useEffect(() => {
        if (!props.currentMusic) return;

        if (props.currentMusic.audioInfo) {
            if (props.currentMusic.audioInfo.primaryLycUrl && props.currentMusic.audioInfo.secondaryLycUrl) {
                if (!rawPrimaryLyc || !rawSecondaryLyc) {
                    return;
                }
            } else if (props.currentMusic.audioInfo.primaryLycUrl && !rawPrimaryLyc) {
                return;
            }
        }

        lrcPlayer.current.setLyric(rawPrimaryLyc, rawSecondaryLyc || undefined);


        // TODO: uncomment this
        audio.current.play();

        return () => {
            if (audio.current) {
                audio.current.pause();
            }
        }
    }, [rawPrimaryLyc, rawSecondaryLyc]);

    return (
        <LyricsPlayerContext.Provider
            value={{
                isPlaying: audioApi.isPlaying(),
                musicInfo: props.currentMusic,
                estimateData: estimateMusicData
            }}
            style={{
                position: "relative"
            }}
        >
            <Box
                sx={{
                    height: {
                        xs: "calc(100% - 80px)",
                        md: "calc(100% - 130px)"
                    },
                    width: "100%",
                }}
            >
                {props.children}
                {
                    isExpendedLyrics && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <ExpendedLyrics
                                lyrics={calculatedLyc}
                                currentLyricIndex={currentLycIndex}
                                close={() => setIsExpendedLyrics(false)}
                            />
                        </Box>
                    )
                }
            </Box>

            <PlayerPanel
                id={props.currentMusic && props.currentMusic.id}
                title={props.currentMusic && props.currentMusic.audioInfo && props.currentMusic.audioInfo.title}
                artist={props.currentMusic && props.currentMusic.audioInfo && props.currentMusic.audioInfo.artist}
                albumImgUrl={props.currentMusic && props.currentMusic.audioInfo.album && props.currentMusic.audioInfo.album.url}
                lyrics={calculatedLyc}
                currentLyricIndex={currentLycIndex}
                audioApi={audioApi}
                isPlaying={audioApi.isPlaying()}
                prevMusic={props.prevMusic}
                nextMusic={props.nextMusic}
                toggleExpendLyrics={() => {
                    setIsExpendedLyrics((prevState) => !prevState);
                }}
            />
        </LyricsPlayerContext.Provider>
    )
}
