import {Box, CircularProgress} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useRef, useState} from "react";
import {useSnackbar} from "notistack";

type Props = {
    onSubmit: (comment: string) => Promise<boolean>
}

export default function DiaryCommentInput(props: Props) {
    const inputRef = useRef<undefined | HTMLDivElement>(undefined);
    const [comment, setComment] = useState<string | null>("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const onSubmit = () => {
        if (isSubmitting) return;

        if (comment && comment.trim().length > 0) {
            setIsSubmitting(true);
            props.onSubmit(comment).then((isSuccess) => {
                setIsSubmitting(false);
                if (isSuccess && inputRef.current) {
                    inputRef.current.textContent = "";
                    setComment("");
                }
            });
        } else {
            enqueueSnackbar(
                '先寫下留言再發送啦，難道你要留言板給你留白麼?',
                {variant: "error"}
            );
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                background: "white",
                borderRadius: "8px",
                boxShadow: "0px 0px 7px 0.5px rgba(0,0,0,0.1)"
            }}
        >
            <Box
                ref={inputRef}
                sx={{
                    display: "inline-block",
                    width: "calc(100% - 48px)",
                    minHeight: "48px",
                    verticalAlign: "bottom",
                    background: isSubmitting ? "#DDD" : "white",
                    padding: "16px",
                    borderRadius: "8px 0 0 8px",
                    border: "none",
                    outline: "none"
                }}
                contentEditable={!isSubmitting}
                onInput={e => setComment(e.currentTarget.textContent)}
            />
            <Box
                sx={{
                    position: "relative",
                    display: "inline-block",
                    width: "48px",
                    height: "100%",
                    cursor: "pointer",
                    color: (theme) => theme.palette.text.primary,
                    ":hover": {
                        color: (theme) => theme.palette.primary.light
                    }
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "end",
                        width: "48px",
                        height: "32px",
                        bottom: "10px",
                        ":hover": {
                            cursor: isSubmitting ? "default" : "pointer"
                        }
                    }}
                    onClick={onSubmit}
                >
                    {
                        isSubmitting ? (
                            <CircularProgress size={22} />
                        ) : (
                            <SendIcon/>
                        )
                    }
                </Box>
            </Box>
        </Box>
    )
}
