import {Box} from "@mui/material";
import React, {ReactNode} from "react";

type Props = {
    isButton?: boolean,
    children: ReactNode,
    style?: React.CSSProperties
}

export default function MediaUploaderItemContainer(props: Props) {
    return (
        <Box
            sx={{
                width: "100%",
                height: "150px",
                position: "relative",
                backgroundColor: props.isButton ? "#FFFFFF88" : "#FFFFFF",
                borderRadius: "8px",
                boxShadow: theme => theme.shadows[1],
                ":hover": props.isButton ? {
                    backgroundColor: "#FFFFFF",
                } : undefined
            }}
            style={props.style}
        >
            {props.children}
        </Box>
    )
}
