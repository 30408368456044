export type SiteErrorReason = {
    code: number,
    message: string
};

export default class SiteError extends Error {
    code: number;

    constructor({code, message}: SiteErrorReason) {
        super(message);
        this.code = code;
    }
}
