import {LyricItem} from "../../../data/lyricsPlayer";
import {Box} from "@mui/material";

type Props = {
    lyrics: LyricItem[],
    currentLyricIndex: number
}

export default function CollapsedLyrics(props: Props) {
    return (
        <Box
            sx={{
                height: "60%",
                fontSize: "28px",
                fontWeight: "bold",
                textAlign: "right",
                margin: "0 32px",
                display: {
                    xs: "none",
                    md: "flex"
                },
                alignItems: "center"
            }}
        >
            <div>
                <div>
                    {props.lyrics[props.currentLyricIndex].text}
                </div>
                {
                    props.lyrics[props.currentLyricIndex].translation && (
                        <div>
                            {props.lyrics[props.currentLyricIndex].translation}
                        </div>
                    )
                }
            </div>
        </Box>
    );
}
