import {Box} from "@mui/material";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import PhotoStyle1 from "../../../../components/PhotoStyle1";
import DiaryPaper from "../../../../components/DiaryPaper";
import DiaryListItem from "../../../../components/DiaryListItem";
import {DiaryId, DiaryListItem as DiaryListItemData, InfiniteScrollDiaryList} from "../../../../data/diary";
import {getAllImagesFromMedias} from "../../../../util/mediaUtils";
import RectangularLoadingSpinner from "../../../../components/RectangularLoadingSpinner";
import AddIcon from '@mui/icons-material/Add';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import RoundGrayButton from "../../../../components/RoundedGrayButton";
import MediaCarousel from "../../../../components/MediaCarousel";
import {CoupleSummaryContext} from "../../../../context/CoupleSummaryContextProvider";

type Props = {
    diaries: InfiniteScrollDiaryList | undefined,
    fetchMoreDiaries: () => void,
    viewingDiary?: DiaryListItemData,
    setViewingDiaryId: (diaryId: DiaryId) => void,
    reloadDiary: () => void,
    createDiary: () => void,
    showExpendedMedia: (index: number) => void
};

type RotateImageItem = {
    diaryIndex: number,
    imageUrl: string
};

export default function DiaryListingFragment(props: Props) {
    const coupleSummaryContext = useContext(CoupleSummaryContext);

    // Image hooks
    const [hoveringIndex, setHoveringIndex] = useState<undefined | number>(undefined);
    const [rotatingIndex, setRotatingIndex] = useState<undefined | number>(undefined);
    const [rotateImages, setRotateImages] = useState<RotateImageItem[]>([]);
    const rotateImagesRef = useRef(rotateImages);
    rotateImagesRef.current = rotateImages;
    const rotatingIndexRef = useRef(rotatingIndex);
    rotatingIndexRef.current = rotatingIndex;
    const rotateTimeoutRef = useRef<undefined | number>();


    // const isViewingDetails = !!props.viewingDiary;

    const updateRotatingIndex = () => {
        //console.log("hi updateRotatingIndex", rotatingIndex, rotateImages);
        if (rotateImagesRef.current.length === 0) {
            setRotatingIndex(undefined);
            rotateTimeoutRef.current = window.setTimeout(updateRotatingIndex, 500);
            return;
        }

        if (rotatingIndexRef.current === undefined || rotatingIndexRef.current >= rotateImagesRef.current.length - 1) {
            setRotatingIndex(0);
        } else {
            setRotatingIndex(rotatingIndexRef.current + 1);
        }

        rotateTimeoutRef.current = window.setTimeout(updateRotatingIndex, 5000);
    }

    useEffect(() => {
        if (!props.diaries) return;

        let rotateImages: RotateImageItem[] = [];
        if (props.diaries!.items!.length > 0) {
            rotateImages = props.diaries!.items!.map(
                (item, index) => {
                    return getAllImagesFromMedias(item.medias).map(image => ({
                        diaryIndex: index,
                        imageUrl: image
                    }));
                }
            ).reduce(
                (prev, curr) => [...prev, ...curr]
            )
        }

        setRotateImages(rotateImages);

        if (rotateTimeoutRef.current !== undefined) {
            window.clearTimeout(rotateTimeoutRef.current);
        }
        rotateTimeoutRef.current = window.setTimeout(updateRotatingIndex, 1);
    }, [props.diaries])


    let imageIndex: number | undefined;
    if (hoveringIndex === undefined) {
        imageIndex = rotatingIndex;
    } else {
        for (let i=0; i<rotateImages.length; i++) {
            const image = rotateImages[i];
            if (image.diaryIndex === hoveringIndex) {
                imageIndex = i;
                break;
            }
        }
    }

    const listingPhoto = useMemo(() => {
        if (imageIndex === undefined) return undefined;

        return (
            <PhotoStyle1
                imageUrl={rotateImages[imageIndex].imageUrl}
            />
        )
    }, [imageIndex, rotateImages]);

    return (
        <>
            <Box
                sx={{
                    width: {
                        xs: 0,
                        md: `40%`
                    },
                    height: "100%",
                    display: {
                        xs: "none",
                        md: "flex"
                    },
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "80%",
                        height: "calc(100% - 120px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 32px"
                    }}
                >
                    {
                        (!props.diaries && imageIndex === undefined) && <RectangularLoadingSpinner color="white"/>
                    }

                    {
                        (!props.viewingDiary && imageIndex !== undefined) && listingPhoto
                    }

                    {
                        (props.viewingDiary) && (
                            <MediaCarousel
                                isShowExpendButton={true}
                                medias={props.viewingDiary.medias}
                                onClickShowExpendButton={props.showExpendedMedia}
                            />
                        )
                    }
                </Box>
            </Box>

            <Box
                sx={{
                    width: {
                        xs: "100%",
                        md: "60%"
                    },
                    height: {
                        xs: "100%",
                        md: "calc(100% - 120px)"
                    },
                    padding: {
                        xs: "16px",
                        md: "0 32px"
                    },
                    fontFamily: "SetoFontSP, Roboto, sans-serif",
                    transition: "transform 0.2s, opacity 0.2s",
                    transformOrigin: "top right",
                    transitionTimingFunction: "ease-in"
                }}
                style={{
                    transform: props.viewingDiary ? "scale(0.7) translate(16px, -16px)" : undefined,
                    opacity: props.viewingDiary ? 0.5 : 1
                }}
            >
                <DiaryPaper
                    isScrollable
                    extraPaddingBottom={80}
                    enableInfiniteScroll={!!props.diaries}
                    isFetchedLastItem={props.diaries && props.diaries.isLast}
                    onFetchMore={props.fetchMoreDiaries}
                >
                    <Box
                        sx={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            background: "#fff5ed",
                            "& > h1": {
                                fontSize: "22px"
                            },
                            "& > h3": {
                                fontSize: "18px"
                            }
                        }}
                    >
                        <h1 style={{textAlign: "right", margin: 0, padding: "6px 4px"}}>{coupleSummaryContext?.data?.config.diary.listingPageTitle}</h1>
                        <h3 style={{textAlign: "right", margin: "0 4px"}}>{coupleSummaryContext?.data?.config.diary.listingPageSubtitle}</h3>
                        <hr style={{borderColor: "2px solid rgb(241 228 218)", marginBottom: "16px"}}/>
                    </Box>

                    <RoundGrayButton
                        height={48}
                        onClick={props.createDiary}
                    >
                        <AddIcon/> 寫點什麼
                    </RoundGrayButton>

                    {
                        props.diaries && props.diaries.items.map((item, index) => (
                            <DiaryListItem
                                key={item.id}
                                diary={item}
                                onClick={() => props.setViewingDiaryId(item.id)}
                                onMouseOver={() => setHoveringIndex(index)}
                                onMouseLeave={() => setHoveringIndex(undefined)}
                            />
                        ))
                    }


                </DiaryPaper>

            </Box>
        </>
    )
}
