import UrlBuilder from "./UrlBuilder";
import Api from "../bundle/Api";

const InfoApiUrl = {
    urls: {
        getMe: "/info/me",
        getSummary: "/info/summary",
        getPlaylist: "/info/playlist"
    },
    builders: {
        getMe: () => {
            return new UrlBuilder(Api.servers.core + Api.urls.info.getMe).build();
        },
        getSummary: (messagingToken?: string) => {
            return new UrlBuilder(Api.servers.core + Api.urls.info.getSummary)
                .withQuery({
                    messagingToken: messagingToken ? messagingToken : undefined
                })
                .build();
        },
        getPlaylist: () => {
            return new UrlBuilder(Api.servers.core + Api.urls.info.getPlaylist).build();
        }
    }
}

export default InfoApiUrl;
