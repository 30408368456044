import {MediaInfo} from "../data/media";

export function getMimeMainType(mime: string) {
    const [type] = mime.split("/");
    return type.toLowerCase();
}

export function getMimeSubtype(mime: string) {
    const [type, subtype] = mime.split("/");
    return subtype;
}

export function getFirstImageFromMedias(mediaInfoList: MediaInfo[] | undefined) {
    if (!mediaInfoList) {
        return undefined;
    }

    for (let mediaInfo of mediaInfoList) {
        if (isImage(mediaInfo)) {
            return mediaInfo;
        }
    }
    return undefined;
}

export function getAllImagesFromMedias(mediaInfoList: MediaInfo[] | undefined) {
    if (!mediaInfoList) {
        return [];
    }

    const images = [];
    for (let mediaInfo of mediaInfoList) {
        if (isImage(mediaInfo)) {
            images.push(mediaInfo.url)
        }
    }
    return images;
}

export function isImage(mediaInfo: MediaInfo) {
    return getMimeMainType(mediaInfo.mime) === "image";
}
