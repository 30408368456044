import React, {useEffect, useState} from "react";
import DiaryListingFragment from "./DiaryListingFragment";
import {Box} from "@mui/material";
import DiaryDetailFragment from "./DiaryDetailFragment";
import {DiaryDetail, DiaryId, DiaryListItem, InfiniteScrollDiaryList} from "../../../data/diary";
import {useNavigate, useParams} from "react-router-dom";
import AppPath from "../../../bundle/AppPath";
import Api from "../../../bundle/Api";
import DiaryEditorDialog from "../../../components/DiaryEditorDialog";
import ExpendedGalleryOverlay from "../../../components/ExpendedGalleryOverlay";

type UrlParams = "diaryId";

const PAGE_COUNT = 20;

export default function DiaryPageContainer() {
    const urlParams = useParams<UrlParams>();
    const navigate = useNavigate();
    const [diaries, setDiaries] = useState<undefined | InfiniteScrollDiaryList>(undefined);
    const [lastLoadTime, setLastLoadTime] = useState<undefined | Date> (undefined);
    const [showInitialExpendedMediaIndex, setShowInitialExpendedMediaIndex] = useState<undefined | number>(undefined);

    // Diary editor hooks
    const [isEditingDiary, setIsEditingDiary] = useState(false);
    const [modifyingDiaryDetail, setModifyingDiaryDetail] = useState<undefined | DiaryDetail>(undefined);

    const onChangeViewingDiaryItem = (diaryId: DiaryId | undefined) => {
        if (diaryId === urlParams.diaryId) return;

        if (diaryId !== undefined) {
            navigate(AppPath.getPathname(AppPath.diaryDetail, {diaryId: diaryId + ""}));
        } else {
            navigate(AppPath.diaryListing);
        }
    };

    const fetchDiaries = async (pageCount: number, last?: DiaryListItem, reload?: boolean) => {

        const apiResponse = await Api.adapters.diary.getDiaries(PAGE_COUNT, last);
        if (apiResponse.status === 200) {
            let newItems;
            let isLast = false;
            if (apiResponse.data.length < pageCount) {
                isLast = true;
            }

            if (!diaries || reload) {
                newItems = apiResponse.data;
            } else {
                newItems = ([...diaries.items, ...apiResponse.data]);
            }


            setDiaries({
                isLast,
                items: newItems
            });
            setLastLoadTime(new Date());
        }
    }

    const getLastDiaryItem = () => {
        let last = undefined;
        if (diaries && diaries.items.length > 0) {
            last = diaries.items[diaries.items.length - 1];
        }
        return last;
    }

    let viewingDiary = undefined;
    if (diaries && diaries.items) {
        for (let diary of diaries.items) {
            if (diary.id === urlParams.diaryId) {
                viewingDiary = diary;
                break;
            }
        }
    }

    useEffect(() => {
        fetchDiaries(PAGE_COUNT, getLastDiaryItem());
    }, [])

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                position: "relative"
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <DiaryListingFragment
                    diaries={diaries}
                    fetchMoreDiaries={() => fetchDiaries(PAGE_COUNT, getLastDiaryItem())}
                    viewingDiary={viewingDiary}
                    setViewingDiaryId={onChangeViewingDiaryItem}
                    reloadDiary={() => fetchDiaries(PAGE_COUNT, undefined, true)}
                    createDiary={() => setIsEditingDiary(true)}
                    showExpendedMedia={setShowInitialExpendedMediaIndex}
                />
            </Box>

            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    pointerEvents: "none",
                    zIndex: 1
                }}
            >
                <DiaryDetailFragment
                    diaryId={urlParams.diaryId}
                    onClose={() => onChangeViewingDiaryItem(undefined)}
                    editDiary={(diaryDetail: DiaryDetail) => {
                        setModifyingDiaryDetail(diaryDetail);
                        setIsEditingDiary(true);
                    }}
                    time={lastLoadTime}
                    showExpendedMedia={setShowInitialExpendedMediaIndex}
                />
            </Box>

            <DiaryEditorDialog
                isShow={isEditingDiary}
                onClose={() => {
                    setIsEditingDiary(false);
                    setModifyingDiaryDetail(undefined);
                }}
                onCreatedDiary={() => fetchDiaries(PAGE_COUNT, undefined, true)}
                onUpdatedDiary={() => {
                    fetchDiaries(PAGE_COUNT, undefined, true);
                    setModifyingDiaryDetail(undefined);
                }}
                modifyingDiary={modifyingDiaryDetail}
            />

            {
                showInitialExpendedMediaIndex !== undefined && viewingDiary && (
                    <ExpendedGalleryOverlay
                        defaultShowIndex={showInitialExpendedMediaIndex}
                        medias={viewingDiary.medias}
                        onClose={() => setShowInitialExpendedMediaIndex(undefined)}
                    />
                )
            }

        </Box>
    )
}
