import 'react-quill/dist/quill.snow.css';
import {Box, Button, Grid, Stack, TextField} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import {DatePicker, DesktopDatePicker, LoadingButton} from "@mui/lab";
import moment, {Moment} from "moment";
import ReactQuill from "react-quill";
import CheckIcon from '@mui/icons-material/Check';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';
import './quillHackStyles.css';
import MediaUploader, {UploadMedia} from "./MediaUploader";
import Api from "../../bundle/Api";
import {useSnackbar} from "notistack";
import {DiaryDetail} from "../../data/diary";
import PerfectScrollbar from "react-perfect-scrollbar";
import {MediaInfo} from "../../data/media";
import {getUniqueId} from "../../util/stringUtils";
import {CoupleSummaryContext} from "../../context/CoupleSummaryContextProvider";

type Props = {
    modifyingDiary?: DiaryDetail,
    onCreated: (diary: DiaryDetail) => void,
    onUpdated: (diary: DiaryDetail) => void
}

type FieldErrors = {
    title?: boolean,
    memoryMoment?: boolean,
    content?: boolean
};

type EditorMode = "create" | "modify";

const modules = {
    toolbar: [
        [{'header': [1, 2, false]}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        // [{ 'color': [] }, { 'background': [] }], // <== bug jor can't use
        ['link', 'image'],
        ['clean']
    ],
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
];

function getDefaultMedia(modifyingMedia?: MediaInfo[]) {
    if (!modifyingMedia) return [];

    return modifyingMedia.map(mediaInfo => ({
        id: getUniqueId(),
        status: "success",
        mediaInfo
    } as UploadMedia));
}

export default function DiaryEditor(props: Props) {
    const editorMode: EditorMode = (props.modifyingDiary) ? "modify" : "create";
    const coupleSummaryContext = useContext(CoupleSummaryContext);

    const [title, setTitle] = useState(props.modifyingDiary?.title || "");
    const [memoryMoment, setMemoryMoment] = useState(props.modifyingDiary?.memoryMoment ? moment(props.modifyingDiary?.memoryMoment) : moment());
    const [contentHtml, setContentHtml] = useState(props.modifyingDiary?.contentHtml || "");
    const [error, setError] = useState<FieldErrors>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const mediaRef = useRef<UploadMedia[]>(getDefaultMedia(props.modifyingDiary?.medias));
    const {enqueueSnackbar} = useSnackbar();

    const resetData = () => {
        setTitle("");
        setMemoryMoment(moment());
        setContentHtml("");
        mediaRef.current = [];
    }

    const handleMemoryMomentChange = (newValue: Moment | null) => {
        if (!newValue) return;
        setMemoryMoment(newValue);
    }

    const onSubmitDiary = () => {
        if (coupleSummaryContext?.data?.isDemo && !coupleSummaryContext.data.me.isAdmin) {
            enqueueSnackbar(
                '你無法在演示模式中新增回憶。',
                {variant: "error"}
            );
            return;
        }

        const error: FieldErrors = {};
        let hasError = false;
        if (title.trim().length === 0) {
            error.title = true;
            hasError = true;
        }
        if (!memoryMoment.isValid()) {
            error.memoryMoment = true;
            hasError = true;
        }
        if (contentHtml.trim().length === 0) {
            error.content = true;
            hasError = true;
        }

        setError(error);
        if (hasError) {
            return;
        }

        for (let uploadMedia of mediaRef.current) {
            if (uploadMedia.status === "pending" || uploadMedia.status === "uploading") {
                enqueueSnackbar(
                    `請稍等，尚有文件等待上載完成。請在所有文件上載完成後再試一次`,
                    {variant: "warning"}
                );
                return;
            }
        }

        mediaRef.current = mediaRef.current.filter((uploadMedia) => uploadMedia.status === "success")

        const data = {
            memoryMoment: memoryMoment.toISOString(),
            title,
            contentHtml,
            mediaIds: mediaRef.current.map(uploadMedia => {
                return uploadMedia.media!.id
            })
        }

        setIsSubmitting(true);

        if (editorMode === "create") {
            Api.adapters.diary.create(data)
                .then(apiResponse => {
                    if (apiResponse.status === 200) {
                        enqueueSnackbar(
                            `建立成功!`,
                            {variant: "success"}
                        );
                        props.onCreated(apiResponse.data as DiaryDetail);
                        resetData();
                    } else {
                        enqueueSnackbar(
                            `建立失敗! 請求救或稍後再試一次。`,
                            {variant: "error"}
                        );
                    }
                    setIsSubmitting(false);
                })
                .catch(error => {
                    enqueueSnackbar(
                        `建立失敗! 請求救或稍後再試一次。`,
                        {variant: "error"}
                    );
                    setIsSubmitting(false);
                });
        } else {
            Api.adapters.diary.edit(props.modifyingDiary!.id, data)
                .then(apiResponse => {
                    if (apiResponse.status === 200) {
                        enqueueSnackbar(
                            `編輯成功!`,
                            {variant: "success"}
                        );
                        props.onUpdated(apiResponse.data as DiaryDetail);
                        resetData();
                    } else {
                        enqueueSnackbar(
                            `編輯失敗! 請求救或稍後再試一次。`,
                            {variant: "error"}
                        );
                    }
                    setIsSubmitting(false);
                })
                .catch(error => {
                    enqueueSnackbar(
                        `編輯失敗! 請求救或稍後再試一次。`,
                        {variant: "error"}
                    );
                    setIsSubmitting(false);
                });
        }
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%"
            }}
        >

            <PerfectScrollbar option={{suppressScrollX: true}}>
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                        height: "56px",
                        paddingLeft: "8px",
                        marginBottom: "16px",
                        paddingRight: "8px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontFamily: "SetoFontSP, Roboto, sans-serif",
                        "& > h1": {
                            fontSize: {
                                xs: "24px",
                                sm: null
                            }
                        }
                    }}
                >
                    <h1>寫什麼好呢~~~</h1>
                    <LoadingButton
                        loading={isSubmitting}
                        variant="contained"
                        startIcon={<CheckIcon/>}
                        onClick={onSubmitDiary}
                        sx={{
                            display: {
                                xs: "none",
                                md: "inline-flex"
                            }
                        }}
                    >
                        完成
                    </LoadingButton>
                </Box>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        width: "100%",
                        height: "calc(100% - 56px - 32px)",
                        marginLeft: "-8px",
                        marginBottom: "16px",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            height: {
                                md: "100%"
                            },
                        }}
                    >
                        <Stack
                            spacing={3}
                            sx={{
                                height: "80%"
                            }}
                        >
                            <TextField
                                label="日記標題"
                                variant="outlined"
                                error={error.title}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                style={{width: "100%"}}
                            />

                            <DatePicker
                                label="記念日期"
                                inputFormat="DD/MM/yyyy"
                                value={memoryMoment}
                                onChange={handleMemoryMomentChange}
                                renderInput={(params) => <TextField {...params} error={error.memoryMoment}/>}
                                desktopModeMediaQuery="@media (min-width: 1280px)"
                            />

                            <Box
                                sx={{
                                    height: theme => ({
                                        md: `calc(100% - ${theme.spacing(3)} * 2 - 112px)`
                                    })
                                }}
                            >
                                <MediaUploader
                                    isImmediateRemoveRemote={editorMode === "create"} // remove remote media only when create mode
                                    defaultMedia={props.modifyingDiary?.medias}
                                    onUpdateMedia={(uploadMedia => mediaRef.current = uploadMedia)}
                                />
                            </Box>
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{
                            height: {
                                md: "80%"
                            },
                            minHeight: {
                                xs: "200px",
                                md: null
                            }
                        }}
                    >
                        <ReactQuill
                            theme="bubble"
                            modules={modules}
                            formats={formats}
                            value={contentHtml}
                            onChange={setContentHtml}
                            style={{
                                height: "100%",
                                border: error.content ? "solid 1px #d32f2f" : "solid 1px #BBBBBB",
                                borderRadius: "4px"
                            }}
                            placeholder="內文 (選取文字後會有文字格式設定功能喔!)"
                        />
                    </Grid>


                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                xs: null,
                                md: "none"
                            }
                        }}
                    >
                        <LoadingButton
                            loading={isSubmitting}
                            variant="contained"
                            startIcon={<CheckIcon/>}
                            onClick={onSubmitDiary}

                        >
                            完成
                        </LoadingButton>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={0}
                        sx={{
                            height: {
                                xs: "56px",
                                md: null
                            }
                        }}
                    />
                </Grid>
            </PerfectScrollbar>
        </Box>
    )
}
