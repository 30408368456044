import {Box} from "@mui/material";
import React, {ReactNode} from "react";

type Props = {
    children?: ReactNode
    backgroundColor?: string,
    padding?: number,
    fontSize?: number,
    fontFamily?: string,
    fontColor?: string
}

export default function Tag(props: Props) {
    return (
        <Box
            className="tag"
            sx={{
                backgroundColor: props.backgroundColor || "white",
                padding: (props.padding || 4) + "px",
                fontSize: theme => props.fontSize || theme.typography.fontSize,
                fontFamily: props.fontFamily,
                color: props.fontColor,
                borderRadius: "4px"
            }}
        >
            {props.children}
        </Box>
    )
}
