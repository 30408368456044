import {Box, useTheme} from "@mui/material";
import DiaryPaper from "../../../../components/DiaryPaper";
import {DiaryDetail, DiaryId} from "../../../../data/diary";
import React, {useCallback, useContext, useEffect, useState} from "react";
import TagList from "../../../../components/TagList";
import Tag from "../../../../components/TagList/Tag";
import {usePrevious, useWindowSize} from "../../../../customHooks";
import FlyingBirds from "../../../../components/FlyingBirds";
import Api from "../../../../bundle/Api";
import {CoupleSummaryContext} from "../../../../context/CoupleSummaryContextProvider";
import RoundedGrayButton from "../../../../components/RoundedGrayButton";
import EditIcon from '@mui/icons-material/Edit';
import DiaryDetailGallery from "../../../../components/DiaryDetailGallery";
import DiaryCommentContainer from "../../../../components/DiaryCommentContainer";
import {useSnackbar} from "notistack";

type Props = {
    diaryId?: DiaryId,
    onClose: () => void,
    editDiary: (diaryDetail: DiaryDetail) => void,
    time?: Date // used for force reload,
    showExpendedMedia: (index: number) => void
}

export default function DiaryDetailFragment(props: Props) {
    const prevDiaryId = usePrevious(props.diaryId);
    const prevTime = usePrevious(props.time);
    const [diaryItem, setDiaryItem] = useState<undefined | DiaryDetail>(undefined);
    const coupleSummary = useContext(CoupleSummaryContext);
    const [windowWidth, windowHeight] = useWindowSize();
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

    const purifyDiaryDetail = (diaryDetail: DiaryDetail) => {
        diaryDetail.comments.reverse();
    }

    const onSubmitComment = useCallback(async (comment: string) => {
        const apiResponse = await Api.adapters.diary.createComment(props.diaryId!, {content: comment});

        if (apiResponse.status === 200) {
            purifyDiaryDetail(apiResponse.data);
            setDiaryItem(apiResponse.data);
            return true;
        } else {
            enqueueSnackbar(
                '留言建立失敗...請稍後再試或者大叫救命',
                {variant: "error"}
            );
            return false;
        }
    }, [props.diaryId, enqueueSnackbar]);

    useEffect(() => {
        if (prevDiaryId === props.diaryId && prevTime === props.time) return;
        if (props.diaryId) {
            Api.adapters.diary.getDiaryDetail(props.diaryId)
                .then(apiResponse => {
                    if (apiResponse.status === 200) {
                        purifyDiaryDetail(apiResponse.data);
                        setDiaryItem(apiResponse.data);
                    } else {
                        props.onClose();
                    }
                })
        } else {
            setDiaryItem(undefined);
        }
    }, [props.diaryId, props.time]);

    let memoryMoment: undefined | Date;
    if (diaryItem) {
        memoryMoment = new Date(diaryItem.memoryMoment);
    }

    const loadingScreen = (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                position: "absolute",
                top: 0,
                left: 0,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <h1>載入中...</h1>
            <Box
                sx={{
                    width: "100%",
                    height: "50%"
                }}
            >
                <FlyingBirds color="black"/>
            </Box>
        </Box>
    );

    return (
        <Box
            id="diaryDetailFragment"
            sx={{
                position: "relative",
                width: {
                    xs: "100%",
                    md: "60%"
                },
                height: "100%",
                padding: "64px 32px",
                opacity: 0,
                pointerEvents: "none",
                transition: "opacity 0.4s",
                transform: "transformX(24px)"
            }}
            style={
                (props.diaryId !== undefined) ? ({
                    opacity: 1,
                    pointerEvents: "all"
                }) : undefined
            }
        >
            {
                (props.diaryId !== undefined) && (
                    <DiaryPaper
                        isScrollable
                        showCloseButton
                        onClose={props.onClose}
                        nonScrollableContent={!diaryItem && loadingScreen}
                    >
                        {
                            diaryItem && (
                                <Box
                                    sx={{
                                        fontFamily: "SetoFontSP"
                                    }}
                                >
                                    <h1>{diaryItem.title}</h1>
                                    <TagList>
                                        <Tag fontSize={16} fontColor="white"
                                             backgroundColor={diaryItem.author.color || "black"}>
                                            {diaryItem.author.displayName}
                                        </Tag>
                                        <Tag fontSize={16}>
                                            <span style={{marginLeft: 0}}>{memoryMoment!.getFullYear()}</span>年<span>{memoryMoment!.getMonth() + 1}</span>月<span>{memoryMoment!.getDate()}</span>日
                                        </Tag>
                                    </TagList>
                                    <hr/>

                                    {
                                        diaryItem.author.email === coupleSummary!.data!.me.email && (
                                            <RoundedGrayButton
                                                height={48}
                                                onClick={() => props.editDiary(diaryItem)}
                                            >
                                                <EditIcon/>&nbsp;&nbsp;編輯
                                            </RoundedGrayButton>
                                        )
                                    }

                                    <div
                                        style={{marginTop: "32px", marginBottom: "32px"}}
                                        dangerouslySetInnerHTML={{__html: diaryItem.contentHtml}}
                                    />

                                    {
                                        windowWidth <= theme.breakpoints.values.md && (
                                            <div
                                                style={{marginTop: "32px", marginBottom: "48px"}}
                                            >
                                                <DiaryDetailGallery
                                                    medias={diaryItem.medias}
                                                    onClick={props.showExpendedMedia}
                                                />
                                            </div>
                                        )
                                    }

                                    <Box
                                        sx={{
                                            marginTop: "48px",
                                            marginBottom: "64px"
                                        }}
                                    >
                                        <h2>留言板</h2>
                                        <hr/>

                                        <DiaryCommentContainer
                                            comments={diaryItem.comments}
                                            onSubmitComment={onSubmitComment}
                                        />
                                    </Box>

                                </Box>
                            )
                        }


                    </DiaryPaper>
                )
            }
        </Box>
    );
}
