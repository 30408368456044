import {Box} from "@mui/material";
import React from "react";

type Props = {
    author: {
        displayName: string,
        color?: string
    },
    body: string,
    time?: Date
}

export default function DiaryCommentItem(props: Props) {
    return (
        <Box
            sx={{
                background: "#ffffff",
                borderRadius: "8px",
                padding: "16px",
                // boxShadow: "0px 0px 7px 0.5px rgba(0,0,0,0.1)",
                margin: "8px 0"
            }}
        >
            <div
                style={{
                    marginBottom: "8px"
                }}
            >
                <span style={{color: props.author.color, fontWeight: "bold"}}>
                    {props.author.displayName}
                </span>

                {
                    props.time && (
                        <Box
                            component="span"
                            sx={{
                                "&::before": {content: "' ‧'"},
                                color: "#CCC",
                                fontSize: "14px"
                            }}
                        >
                            <span style={{marginLeft: 0}}>{props.time!.getFullYear()}</span>年
                            <span>{props.time!.getMonth() + 1}</span>月
                            <span>{props.time!.getDate()}</span>日 <span>{props.time!.getHours()}</span>:
                            <span>{props.time!.getMinutes()}</span>
                        </Box>
                    )
                }

            </div>
            {props.body}
        </Box>
    )
}
