import _ from "lodash";

const SupportedMimeTypes = {
    image: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml",
        "image/webp",
        "image/bmp",
        "image/x-icon"
    ],
    audio: [
        "audio/mpeg",
        "audio/mp3",
        "audio/wav",
        "audio/webm"
    ],
    video: [
        "video/mpeg",
        "video/mp4",
        "video/webm"
    ],
    checkIsSupport: (targetMime: string, supportedMimeTypes: string[]) => {
        return _.includes(supportedMimeTypes, targetMime);
    }
}

export default SupportedMimeTypes;
