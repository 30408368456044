import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {LyricItem} from "../../../data/lyricsPlayer";
import CircleCloseButton from "../../CircleCloseButton";

type Props = {
    lyrics: LyricItem[],
    currentLyricIndex: number,
    close: () => void
}

function isInView(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) - 120 &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export default function ExpendedLyrics(props: Props) {
    const [isInitializing, setIsInitializing] = useState(false);
    const containerRef = useRef<HTMLDivElement | undefined>(undefined);

    const updateLyricPosition = (isForce?: boolean) => {
        const prevLyrics = document.getElementById(`lyrics_${props.currentLyricIndex > 0 ? props.currentLyricIndex - 1 : 0}`)
        const currentLyrics = document.getElementById(`lyrics_${props.currentLyricIndex}`);

        if (!prevLyrics || !currentLyrics || (!isForce && !isInView(prevLyrics))) return;
        currentLyrics.scrollIntoView({
            behavior: "smooth",
            block: "center"
        });
    }

    useEffect(updateLyricPosition, [props.lyrics, props.currentLyricIndex]);

    return (
        <Box
            id="expendedLyricsContainer"
            ref={(element: HTMLDivElement) => {
                containerRef.current = element;
                setIsInitializing(true);
                updateLyricPosition(true);
            }}
            sx={{
                position: "relative",
                height: "100%",
                width: "100%",
                background: "white",
                zIndex: 100,
                padding: "32px",
                overflowY: "auto",
                scrollBehavior: "smooth",
                scrollbarWidth: "none"
            }}
        >
            <Box
                sx={{
                    position: "fixed",
                    top: "16px",
                    right: "16px"
                }}
                onClick={props.close}
            >
                <CircleCloseButton size={32}/>
            </Box>

            {
                isInitializing && (
                    <Box
                        id="expendedLyricsContent"
                        sx={{
                            width: "100%",
                            padding: `${containerRef.current!.offsetHeight / 2 - 18 - 24 - 24}px 0`
                        }}
                    >
                        {
                            props.lyrics.map((item, index) => {
                                return (
                                    <Box
                                        key={item.time}
                                        id={`lyrics_${index}`}
                                        className={index === props.currentLyricIndex ? "active" : undefined}
                                        sx={{
                                            margin: "24px 0",
                                            fontSize: "18px",
                                            textAlign: "center",
                                            minHeight: "18px",
                                            transition: "font-size 0.2s",
                                            "&.active": {
                                                fontSize: "24px",
                                                fontWeight: "bold",
                                                color: "#001b4a"
                                            }
                                        }}
                                    >
                                        <Box>{item.text}</Box>
                                        <Box>{item.translation}</Box>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                )
            }

        </Box>
    )
}
