import axios, {AxiosResponse} from "axios";
import AuthService from "../service/AuthService";
import Api from "../bundle/Api";
import {CoupleSummary, PlaylistItem} from "../data/info";

const InfoAdapter = {
    getSummary: async (messagingToken?: string): Promise<AxiosResponse<CoupleSummary>> => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.get<CoupleSummary>(Api.urlBuilders.info.getSummary(messagingToken), {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
    },

    getPlaylist: async (): Promise<AxiosResponse<PlaylistItem[]>> => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.get<PlaylistItem[]>(Api.urlBuilders.info.getPlaylist(), {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
    }
}

export default InfoAdapter;
