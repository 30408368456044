import {Box} from "@mui/material";
import React from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

type Props = {
    children?: React.ReactNode;
    component?: React.ElementType;
    ref?: React.Ref<unknown>;
    sx?: SxProps<Theme>
}

export default function Page(props: Props) {
    return (
        <Box
            {...props}
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                ...props.sx
            }}
        >
            {props.children}
        </Box>
    )
}
