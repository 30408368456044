import {Box} from "@mui/material";
import React, {ReactNode, useEffect, useState} from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircleCloseButton from "../CircleCloseButton";
import {usePrevious} from "../../customHooks";

type Props = {
    children?: ReactNode,
    extraPaddingBottom?: number,
    showCloseButton?: boolean,
    enableInfiniteScroll?: boolean,
    isFetchedLastItem?: boolean,
    onFetchMore?: () => void,
    onClose?: () => void,
    isScrollable?: boolean,
    nonScrollableContent?: ReactNode
}

export default function DiaryPaper(props: Props) {
    const [listBottomMessage, setListBottomMessage] = useState<undefined | string>(undefined);
    const prevIsFetchedLastItem = usePrevious(props.isFetchedLastItem);

    const onScrolledBottom = () => {
        if (!props.enableInfiniteScroll || props.isFetchedLastItem || !props.onFetchMore) return;

        setListBottomMessage("Loading~~~");
        props.onFetchMore();
    }

    useEffect(() => {
        if (prevIsFetchedLastItem !== props.isFetchedLastItem) {
            setListBottomMessage(undefined);
        }
    }, [props.isFetchedLastItem])

    const scrollbarContainer = (children: ReactNode) => (
        <PerfectScrollbar
            option={{suppressScrollX: true}}
            onYReachEnd={onScrolledBottom}
        >
            {children}
        </PerfectScrollbar>
    );

    const content = (
        <Box
            sx={{
                position: "relative",
                height: props.isScrollable ? undefined : "100%",
                margin: {
                    xs: `0 8px ${8 + (props.extraPaddingBottom || 0)}px 8px`,
                    md: `0 24px ${8 + (props.extraPaddingBottom || 0)}px 24px`,
                }
            }}
        >
            {props.children}

            <Box
                sx={{
                    height: listBottomMessage ? "24px" : 0,
                    transition: "height 0.2s"
                }}
            />

            {
                props.enableInfiniteScroll && (
                    <Box
                        sx={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            bottom: 0,
                            opacity: listBottomMessage ? 1 : 0,
                            transition: "opacity 0.2s",
                            pointerEvents: "none"
                        }}
                    >
                        Loading...
                    </Box>
                )
            }
        </Box>
    );

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                background: "#fff5ed",
                borderRadius: "16px",
                boxShadow: theme => theme.shadows[5],
                padding: "16px 4px",
            }}
        >
            {
                props.showCloseButton && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "-18px",
                            right: "-18px",
                            cursor: "pointer"
                        }}
                    >
                        <CircleCloseButton size={36} onClose={props.onClose}/>
                    </Box>
                )
            }

            {
                props.isScrollable ? (
                    <>
                        {scrollbarContainer(content)}
                        {props.nonScrollableContent}
                    </>
                ) : (
                    content
                )
            }
        </Box>
    )
}
