import FirebaseService from "./FirebaseService";
import {getMessaging, getToken, isSupported, onMessage, MessagePayload} from "firebase/messaging";

export default class MessagingService {
    static init() {
        FirebaseService.init();
    }

    static getToken() {
        return isSupported()
            .then((isSupport) => {
                if (isSupport) {
                    const messaging = getMessaging();
                    return getToken(messaging, {vapidKey: "BIdVHb2DsZTlX7uiPbHxCO-dDOX0qb0k-FWd8hkzVAOynQ1E-enkcjTyYVqSqu7b24DMoYZa26xj89fv4-T_1bM"});
                } else {
                    alert("Messaging is not supported in this browser");
                    return undefined;
                }
            })
            .catch((e) => {
                console.error("Messaging Service getToken failed", e);
                return undefined
            });
    }

    // Return unsubscribe function
    static newMessageListener(callback: (payload: MessagePayload) => void) {
        return onMessage(getMessaging(), (payload) => {
            callback(payload);
        });
    }
}
