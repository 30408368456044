import React from 'react';

export default class LocalStorageUtil {
    static setValue(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static removeValue(key: string) {
        localStorage.removeItem(key);
    }

    static getValue(key: string) {
        const value = localStorage.getItem(key);

        if (value && value !== 'undefined') {
            return JSON.parse(value);
        }

        return undefined;
    }
}
