import FlyingBirds from "../FlyingBirds";
import React from "react";
import {Box} from "@mui/material";

type Props = {
    canFadeOut: boolean,
    onFadeInCompleted?: () => void,
    onFadeOutCompleted?: () => void
};

type State = {
    step: number,
    isFadingOut: boolean
};

export const STEP_INITIAL = 0;
export const STEP_FADE_IN = 1;
export const STEP_FADE_IN_COMPLETE = 2;
export const STEP_FADE_OUT_1 = 3;
export const STEP_FADE_OUT_2 = 4;
export const STEP_FADE_OUT_COMPLETE = 5;

export default class CoupleSummaryLoadingOverlay extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            step: STEP_INITIAL,
            isFadingOut: false
        }
    }

    filteredSetStep(newStep: number) {
        if (this.state.step < newStep) {
            this.setState({step: newStep});
        }
    }

    componentDidMount() {
        setTimeout(() => this.filteredSetStep(STEP_FADE_IN), 1);
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.canFadeOut === this.props.canFadeOut && prevState.step === this.state.step) {
            return;
        }

        if (prevState.step === STEP_INITIAL && this.state.step === STEP_FADE_IN) {
            setTimeout(() => {
                this.filteredSetStep(STEP_FADE_IN_COMPLETE);
                this.props.onFadeInCompleted && this.props.onFadeInCompleted();
            }, 1000);
        } else if (this.props.canFadeOut && !this.state.isFadingOut && this.state.step === STEP_FADE_IN_COMPLETE) {
            setTimeout(() => {
                this.setState({isFadingOut: true}, () => {
                    this.filteredSetStep(STEP_FADE_OUT_1);
                })
            }, 1);
        } else if (this.props.canFadeOut && this.state.step === STEP_FADE_OUT_1) {
            setTimeout(() => {
                this.filteredSetStep(STEP_FADE_OUT_2);
            }, 300);
        } else if (this.state.step === STEP_FADE_OUT_2) {
            setTimeout(() => {
                this.filteredSetStep(STEP_FADE_OUT_COMPLETE);
            }, 300);
        } else if (this.state.step === STEP_FADE_OUT_COMPLETE) {
            this.props.onFadeOutCompleted && this.props.onFadeOutCompleted();
        }
    }

    render() {
        const opacity = (this.state.step === STEP_INITIAL || this.state.step >= STEP_FADE_OUT_2) ? 0 : 1;
        console.log("step", this.state.step, ", opacity", opacity);

        return (
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    background: this.state.step < STEP_FADE_OUT_1 ? "#001828" : "#f1d7bb",
                    opacity: opacity,
                    transition: "opacity 0.3s, background 0.5s",
                    zIndex: 999
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        bottom: "-10%",
                        left: "-50%",
                        width: "200%",
                        height: "50%",
                        background: "radial-gradient(ellipse at center bottom, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 70%)",
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        height: "300px",
                    }}
                >
                    <FlyingBirds color="white"/>
                </div>

                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "32px"
                    }}
                >
                    載入中...
                </div>
            </Box>
        )
    }
}
