import {Box} from "@mui/material";
import React from "react";
import LazyLoad, {LazyLoadProps} from "react-lazyload";
import {DiaryListItem as DiaryListItemData} from "../../data/diary";
import {getFirstImageFromMedias, getMimeMainType} from "../../util/mediaUtils";
import {MediaInfo} from "../../data/media";
import {lightOrDark} from "../../util/colorUtils";

type Props = {
    isLazyLoad?: boolean,
    lazyLoadConfig?: LazyLoadProps,
    diary: DiaryListItemData,
    onClick: (e: React.MouseEvent) => void,
    onMouseOver?: (e: React.MouseEvent) => void,
    onMouseLeave?: (e: React.MouseEvent) => void
}



export default function DiaryListItem(props: Props) {
    const memoryMoment = new Date(props.diary.memoryMoment);

    const tagBackgroundColor = props.diary.author.color || "#000000";
    const mode = lightOrDark(tagBackgroundColor);
    const tagFontColor = mode === "light" ? "#000000" : "#FFFFFF";

    const renderBody = () => (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: {
                    xs: "64px",
                    md: "80px"
                },
                background: "white",
                borderRadius: "16px",
                margin: "8px 0",
                display: "flex",
                overflow: "hidden",
                cursor: "pointer",
                transition: "transform 0.2s",
                "&:hover": {
                    transform: "translateX(-8px)"
                }
            }}
            onClick={props.onClick}
            onMouseOver={props.onMouseOver}
            onMouseLeave={props.onMouseLeave}
        >
            <Box
                sx={{
                    width: {
                        xs: "32px",
                        md: "48px"
                    },
                    borderRadius: "16px 0 0 16px",
                    background: tagBackgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Box
                    sx={{
                        transform: "rotate(-90deg)",
                        color: tagFontColor,
                        fontSize: {
                            xs: "14px",
                            md: "18px"
                        },
                        fontWeight: "bold"
                    }}
                >
                    {props.diary.author.displayName}
                </Box>
            </Box>

            <Box
                style={{
                    backgroundImage: `url('${getFirstImageFromMedias(props.diary.medias)?.url}')`
                }}
                sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "20%",
                    height: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center"
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        background: "linear-gradient(to right, #FFF 0%, #FFFFFF00 30%)",
                        borderRadius: "0 16px 16px 0"
                    }}
                />
            </Box>

            <Box
                sx={{
                    width: "calc(80% - 48px)",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center"
                }}
            >
                <Box
                    sx={{
                        fontSize: {
                            xs: "10px",
                            md: "14px"
                        },
                        fontWeight: "bold",
                        margin: {
                            xs: "4px 8px",
                            md: "8px 16px"
                        },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        "& span": {
                            fontSize: {
                                xs: "14px",
                                md: "18px"
                            },
                            margin: "0 4px"
                        }
                    }}
                >
                    <span style={{marginLeft: 0}}>{memoryMoment.getFullYear()}</span>年<span>{memoryMoment.getMonth() + 1}</span>月<span>{memoryMoment.getDate()}</span>日
                </Box>
                <Box
                    sx={{
                        margin: {
                            xs: "4px 8px",
                            md: "8px 16px"
                        },
                        fontSize: {
                            xs: "16px",
                            md: "20px"
                        },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                >
                    {props.diary.title}
                </Box>
            </Box>
        </Box>
    );

    if (props.isLazyLoad) {
        return (
            <LazyLoad {...props.lazyLoadConfig} height={96}>
                {renderBody()}
            </LazyLoad>
        );
    } else {
        return renderBody();
    }
}
