import {Box} from "@mui/material";
import React, {ReactNode} from "react";
import {AnniversaryItem} from "../../../data/anniversary";

type Props = {
    item: AnniversaryItem,
    size?: number,

    showInnerBorder?: boolean,
    borderRadius?: number,
    backgroundColor?: string,
    fontColor?: string,
    dayCountFontSize?: number,
    dayNameFontSize?: number
}

export default function AnniversaryBlock(props: Props) {
    const today = new Date();
    const anniversaryDate = new Date(props.item.datetime);
    const timeDiff = today.getTime() - anniversaryDate.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    const innerBorder = (children: ReactNode) => (
        <Box
            sx={{
                position: "relative",
                borderRadius: props.borderRadius ? `${props.borderRadius}px` : undefined,
                width: "calc(100% - 16px)",
                height: "calc(100% - 16px)",
                border: `solid 2px ${props.fontColor || "inherit"}`,
                margin: "8px"
            }}
        >
            {children}
        </Box>
    );

    const content = () => (
        <Box
            sx={{
                width: "100%",
                height: `100%`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    padding: (props.size || 160) / 20 + "px"
                }}
            >
                Day
            </Box>

            <Box
                sx={{
                    fontWeight: "bold",
                    fontSize: props.dayCountFontSize,
                }}
            >
                {dayDiff}
            </Box>

            <Box
                sx={{
                    padding: (props.size || 160) / 30 + "px",
                    fontSize: props.dayNameFontSize,
                    fontWeight: "bold",
                    textAlign: "center"
                }}
            >
                {props.item.title}
            </Box>
        </Box>
    );

    return (
        <Box
            sx={{
                background: props.backgroundColor || "white",
                width: props.size || "100%",
                height: props.size || undefined,
                borderRadius: props.borderRadius ? `${props.borderRadius}px` : undefined,
                color: props.fontColor,
                padding: (props.size || 80) / 20 + "px",
            }}
        >

            {
                props.showInnerBorder ? innerBorder(content()) : content()
            }

        </Box>
    )
}
