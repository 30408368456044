import {
    CreateDiaryCommentRequestBody,
    CreateDiaryRequestBody,
    DiaryDetail,
    DiaryListItem,
    EditDiaryRequestBody,
    GetDiaryWithPaginationRequestBody
} from "../data/diary";
import AuthService from "../service/AuthService";
import axios from "axios";
import Api from "../bundle/Api";
import {MediaInfo} from "../data/media";
import {ApiError} from "../data/error";
import Config from "../config/Config";

const DiaryAdapter = {
    getDiaries: async (pageCount: number, last?: DiaryListItem) => {
        const accessToken = await AuthService.getAccessToken();
        const body: GetDiaryWithPaginationRequestBody = {pageCount, last, isShowTest: Config.isTest};
        return await axios.post<DiaryListItem[]>(Api.urlBuilders.diary.getDiaryList(), body, {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
    },

    getDiaryDetail: async (diaryId: string) => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.get<DiaryDetail>(Api.urlBuilders.diary.getDiaryDetail(diaryId), {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
    },

    uploadCreateDiaryMedia: (file: File, progressCallback?: (percentage: number) => void) => {
        let formData = new FormData();
        formData.set("media", file, file.name);

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        return {
            cancel: source.cancel,
            promise: AuthService.getAccessToken()
                .then(accessToken => {
                    return axios.post<MediaInfo | ApiError>(Api.urlBuilders.diary.uploadCreateDiaryMedia(), formData, {
                        headers: {
                            "Authorization": "Bearer " + accessToken
                        },
                        onUploadProgress: (progressEvent: ProgressEvent) => {
                            progressCallback && progressCallback(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                        },
                        cancelToken: source.token
                    })
                })
        };
    },

    removeCreateDiaryMedia: async (mediaId: string) => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.delete<DiaryDetail>(Api.urlBuilders.diary.removeCreateDiaryMedia(mediaId), {
            headers: {
                "Authorization": "Bearer " + accessToken
            }
        });
    },

    create: async (createData: CreateDiaryRequestBody) => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.post<DiaryDetail>(
            Api.urlBuilders.diary.create(),
            Config.isTest === undefined ? createData : {...createData, isShowTest: Config.isTest},
            {
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            });
    },

    edit: async (diaryId: string, editData: EditDiaryRequestBody) => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.put<DiaryDetail>(
            Api.urlBuilders.diary.edit(diaryId),
            Config.isTest === undefined ? editData : {...editData, isShowTest: Config.isTest},
            {
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            }
        );
    },

    createComment: async (diaryId: string, data: CreateDiaryCommentRequestBody) => {
        const accessToken = await AuthService.getAccessToken();
        return await axios.post<DiaryDetail>(
            Api.urlBuilders.diary.createComment(diaryId),
            data,
            {
                headers: {
                    "Authorization": "Bearer " + accessToken
                }
            }
        );
    }
}

export default DiaryAdapter;
