import _ from "lodash";

export type PathVariables = {[key: string] : string};

const replaceTextWithColonPattern = (text: string, data: PathVariables): string => {
    const regex = new RegExp(':(' + _.keys(data).join('|') + ')', 'g');
    return text.replace(regex, (m, $1) => data[$1] || '');
}

const AppPath = {
    landing: "/",
    diaryListing: "/diary",
    diaryDetail: "/diary/:diaryId",

    getPathname: (pathDefinition: string, pathVariables?: PathVariables): string => {
        return pathVariables
            ? replaceTextWithColonPattern(pathDefinition, pathVariables)
            : pathDefinition;
    }
};



export default AppPath;
