import {Box} from "@mui/material";
import styles from "./index.module.scss";

type Props = {
    color?: "black" | "white"
}

export default function FlyingBirds({color = "white"}: Props) {
    let birdStyle = styles.bird;
    if (color === "white") {
        birdStyle += " " + styles.white;
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%"
            }}
            className={styles.container}
        >
            <div className={styles.birdContainer + " " + styles.birdContainerOne}>
                <div className={birdStyle + " " + styles.birdOne}/>
            </div>

            <div className={styles.birdContainer + " " + styles.birdContainerTwo}>
                <div className={birdStyle + " " + styles.birdTwo}/>
            </div>

            <div className={styles.birdContainer + " " + styles.birdContainerThree}>
                <div className={birdStyle + " " + styles.birdThree}/>
            </div>

            <div className={styles.birdContainer + " " + styles.birdContainerFour}>
                <div className={birdStyle + " " + styles.birdFour}/>
            </div>
        </Box>
    )
}
