import {Box, Tooltip} from "@mui/material";
import {useRef, MouseEvent, TouchEvent, useState, useEffect} from "react";
import {AudioApi} from "../../../data/lyricsPlayer";

type Props = {
    audioId?: string,
    collapsedHeight: number,
    expendedHeight: number,
    progress: number,
    audioApi: AudioApi,
    onSeek: (progress: number) => void
}

function toHHMMSS(duration: number): string {
    duration = Math.trunc(duration);

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - (hours * 3600)) / 60);
    const seconds = duration - (hours * 3600) - (minutes * 60);

    let hoursStr = undefined;
    let minutesStr = minutes + "";
    let secondsStr = seconds + "";

    if (hours > 0 && hours < 10) {
        hoursStr = "0" + hours;
    } else if (hours >= 10) {
        hoursStr = hours + "";
    }
    if (minutes < 10) {
        minutesStr = "0" + minutes;
    }
    if (seconds < 10) {
        secondsStr = "0" + seconds;
    }

    if (hoursStr) {
        return hoursStr + ":" + minutesStr + ":" + secondsStr;
    } else {
        return minutesStr + ":" + secondsStr;
    }
}

export default function PlayerProgressBar(props: Props) {
    const barContainer = useRef<undefined | HTMLDivElement>(undefined);
    const [hoverPosition, setHoverPosition] = useState<undefined | number>(undefined);
    const isSeeking = useRef(false);

    const onStartSeeking = (x: number) => {
        const newHoverPosition = x / barContainer.current!.offsetWidth;
        setHoverPosition(newHoverPosition);
        isSeeking.current = true;
        props.audioApi.setCurrentTime(props.audioApi.getDuration() * newHoverPosition);
    };

    const onEndSeeking = () => {
        isSeeking.current = false;
    };

    const onSeek = (x: number) => {
        const newHoverPosition = x / barContainer.current!.offsetWidth;
        setHoverPosition(newHoverPosition);
        if (isSeeking.current) {
            props.audioApi.setCurrentTime(props.audioApi.getDuration() * newHoverPosition);
        }
    }

    const onFocusOut = () => {
        isSeeking.current = false
        setHoverPosition(undefined);
    }

    return (
        <Tooltip
            title={toHHMMSS(props.audioApi.getDuration() * (hoverPosition || 0))}
            arrow
            followCursor
            placement="top"
            open={hoverPosition !== undefined}
        >
            <Box
                ref={barContainer}
                sx={{
                    position: "relative",
                    width: "100%",
                    height: `${props.collapsedHeight}px`,
                    background: "#EEEEEE",
                    borderRadius: "16px",
                    transition: "height 0.2s",
                    cursor: "pointer",
                    ":hover": {
                        height: `${props.expendedHeight}px`,
                    }
                }}
                onMouseEnter={(e: MouseEvent<HTMLDivElement>) => isSeeking.current = false}
                onMouseDown={(e: MouseEvent<HTMLDivElement>) => onStartSeeking(e.clientX)}
                onTouchStart={(e: TouchEvent<HTMLDivElement>) => onStartSeeking(e.targetTouches[0].clientX)}
                onMouseUp={onEndSeeking}
                onTouchEnd={onFocusOut}
                onMouseMove={(e: MouseEvent<HTMLDivElement>) => onSeek(e.clientX)}
                onTouchMove={(e: TouchEvent<HTMLDivElement>) => onSeek(e.targetTouches[0].clientX)}
                onMouseLeave={onFocusOut}
            >
                <Box
                    sx={{
                        height: `100%`,
                        background: "linear-gradient(#ffca23, #cf9d00)",
                        borderRadius: "16px"
                    }}
                    style={{
                        width: `${props.progress * 100}%`,
                    }}
                />


                {
                    hoverPosition !== undefined && (
                        <Box
                            sx={{
                                position: "absolute",
                                height: `${props.expendedHeight + 8}px`,
                                width: `${props.expendedHeight + 8}px`,
                                pointerEvents: "none",
                                background: "#FFFFFFAA",
                                borderRadius: `${(props.expendedHeight + 8) / 2}px`,
                                boxShadow: (theme) => theme.shadows[5]
                            }}
                            style={{
                                top: `-4px`,
                                left: `calc(${(hoverPosition || 0) * 100}% - ${(props.expendedHeight + 8) / 2}px`,
                            }}
                        />
                    )
                }

            </Box>
        </Tooltip>
    );
}
