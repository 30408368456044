import DiaryCommentInput from "./DiaryCommentInput";
import React from "react";
import {DiaryComment} from "../../data/diary";
import DiaryCommentItem from "./DiaryCommentItem";

type Props = {
    comments?: DiaryComment[],  // expected in descending order of creation date
    onSubmitComment: (comment: string) => Promise<boolean> // isSuccess
}

export default function DiaryCommentContainer(props: Props) {
    if (props.comments) {
        console.log(JSON.stringify(props.comments));
        console.log(JSON.stringify(props.comments.reverse()));
    }
    return (
        <>
            <DiaryCommentInput
                onSubmit={props.onSubmitComment}
            />

            {
                props.comments && props.comments.length > 0 ?
                    props.comments.map((item, index) => (
                    <DiaryCommentItem
                        key={props.comments!.length - index}
                        author={{
                            displayName: item.author.displayName,
                            color: item.author.color
                        }}
                        body={item.content}
                        time={new Date(item.createTime)}
                    />
                )) : (
                        <div style={{textAlign: "center", margin: "16px"}}>這裡還是啥米都沒有，有興趣留個言嗎?</div>
                )
            }
        </>
    )
}
