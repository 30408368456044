export function lightOrDark(color: string): "light" | "dark" {

    // Variables for red, green, blue values
    let r: number, g: number, b: number, hsp: number;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        const colorArr = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = +colorArr![1];
        g = +colorArr![2];
        b = +colorArr![3];
    }
    else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        // @ts-ignore
        const rgb = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

        r = rgb >> 16;
        g = rgb >> 8 & 255;
        b = rgb & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {
        return 'light';
    }
    else {
        return 'dark';
    }
}
