import {MediaInfo} from "../../data/media";
import React, {ReactElement, useState} from "react";
import {getMimeMainType} from "../../util/mediaUtils";
import {Carousel} from "react-responsive-carousel";
import "./carouselHackStyle.scss";
import {Box, IconButton} from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import VideocamIcon from '@mui/icons-material/Videocam';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

type Props = {
    isShowExpendButton: boolean,
    medias: MediaInfo[],
    defaultShowIndex?: number,
    onClickShowExpendButton?: (index: number) => void
}

const getDiaryDetailCarouselItems = (media: MediaInfo[]) => {
    const items: ReactElement[] = [];
    for (let current of media) {
        if (getMimeMainType(current.mime) === "image") {
            items.push(
                <div
                    key={current.id}
                    style={{
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url('${current.url}')`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center"
                    }}
                />
            );
        } else if (getMimeMainType(current.mime) === "video") {
            items.push(
                <video
                    controls
                    key={current.id}
                    src={current.url}
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                />
            );
        } else if (getMimeMainType(current.mime) === "audio") {
            items.push(
                <audio
                    controls
                    key={current.id}
                    src={current.url}
                    style={{
                        width: "80%"
                    }}
                />);
        }
    }
    return items;
}

const getDiaryDetailCarouselThumbs = (media: MediaInfo[]) => {
    const items: ReactElement[] = [];
    for (let current of media) {
        if (getMimeMainType(current.mime) === "image") {
            items.push(
                <div
                    key={current.id}
                    style={{
                        width: "100%",
                        height: "80px",
                        backgroundImage: `url('${current.url}')`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center"
                    }}
                />
            );
        } else if (getMimeMainType(current.mime) === "video") {
            items.push(
                <div
                    key={current.id}
                    style={{
                        width: "100%",
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <VideocamIcon/>
                </div>
            );
        } else if (getMimeMainType(current.mime) === "audio") {
            items.push(
                <div
                    key={current.id}
                    style={{
                        width: "100%",
                        height: "80px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <MusicNoteIcon/>
                </div>
            );
        } else {
            items.push(
                <div
                    key={current.id}
                    style={{
                        width: "150px",
                        height: "150px"
                    }}
                >
                    <QuestionMarkIcon/>
                </div>
            );
        }
    }
    return items;
}

export default function MediaCarousel(props: Props) {
    const [currentIndex, setCurrentIndex] = useState(props.defaultShowIndex || 0);
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%"
            }}
            className={props.isShowExpendButton ? undefined : "expended"}
        >
            <Carousel
                showStatus={false}
                showThumbs={true}
                selectedItem={props.defaultShowIndex}
                onChange={(index) => {
                    setCurrentIndex(index);
                }}
                renderThumbs={() => getDiaryDetailCarouselThumbs(props.medias)}
            >
                {getDiaryDetailCarouselItems(props.medias)}
            </Carousel>

            {
                props.medias?.length > 0 && props.isShowExpendButton && (
                    <Box
                        sx={{
                            position: "absolute",
                            right: "-36px",
                            bottom: "94px",
                        }}
                    >
                        <IconButton
                            aria-label="Zoom"
                            size="large"
                            color="primary"
                            onClick={() => props.onClickShowExpendButton && props.onClickShowExpendButton(currentIndex)}
                        >
                            <ZoomOutMapIcon fontSize="inherit"/>
                        </IconButton>
                    </Box>
                )
            }

        </Box>
    )
}
