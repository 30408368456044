import React, {useEffect} from "react";
import {MediaInfo} from "../../data/media";
import ResizableImage from "./ResizableImage";
import {Box} from "@mui/material";

type Props = {
    containerWidth: number,
    containerHeight: number
    mediaInfo: MediaInfo,
    onResized?: (width: number, height: number) => void
}

export const getResizedDimension = (containerWidth: number, containerHeight: number, mediaWidth: number, mediaHeight: number) => {
    const ratio = mediaWidth / mediaHeight;

    let imgCalWidth: number = mediaWidth, imgCalHeight: number = mediaHeight;

    if (imgCalWidth > containerWidth) {
        imgCalWidth = containerWidth;
        imgCalHeight = imgCalWidth / ratio;
        //console.log("too large width", imgCalWidth, imgCalHeight, ratio);
    }

    if (imgCalHeight > containerHeight) {
        imgCalHeight = containerHeight;
        imgCalWidth = imgCalHeight * ratio;
        //console.log("too large height");
    }

    return [imgCalWidth, imgCalHeight];
}

export default function ResizableMedia(props: Props) {
    const [type] = props.mediaInfo.mime.split("/");

    if (type.toLowerCase() === "image") {
        return (
            <ResizableImage
                containerWidth={props.containerWidth}
                containerHeight={props.containerHeight}
                imageUrl={props.mediaInfo.url}
                onResized={props.onResized}
            />
        );
    } else {
        return <Box/>;
    }
}
