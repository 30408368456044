import SiteErrorReasons from "../bundle/SiteErrorReasons";
import SiteError from "../SiteError";

export function getMimeType(file: File) {
    if (window.FileReader && window.Blob) {
        return file.type;
    } else {
        throw new SiteError(SiteErrorReasons.CANNOT_DETERMINE_FILE_TYPE);
    }
}
