import React from 'react';
import styles from './styles.module.scss';

function getSnowflakes() {
    const flakes = [];
    for (let i=0; i<20; i++) {
        flakes.push(
            <div className={styles.snowflake}>
                <div className={styles.rotate}>
                    ❅
                </div>
            </div>
        )
    }
    return flakes;
}

export default function SnowfallOverlayV2() {
    return (
        <div className={styles.snowflakes} aria-hidden="true">
            {getSnowflakes()}
        </div>
    )
}
