import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import firebase from "firebase/compat";

export default class FirebaseService {
    static firebaseConfig = {
        apiKey: "AIzaSyD_BaEK8q9Pv8KitVqCoGvafhm8k-MV_V4",
        authDomain: "noble-history-332109.firebaseapp.com",
        projectId: "noble-history-332109",
        storageBucket: "noble-history-332109.appspot.com",
        messagingSenderId: "70123865963",
        appId: "1:70123865963:web:a548a748533e987e4cae2e"
    };

    static init() {
        if (firebase.apps.length === 0) {
            // Initialize Firebase
            const app = initializeApp(FirebaseService.firebaseConfig);
            const analytics = getAnalytics(app);
        }
    }
}
