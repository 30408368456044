import React from "react";
import {Box} from "@mui/material";
import {AnniversaryItem} from "../../data/anniversary";
import AnniversaryBlock from "./AnniversaryBlock";

type Props = {
    items: AnniversaryItem[],
    size: number,
    borderRadius?: number,
    backgroundColor?: string,
    fontColor?: string,
    dayCountFontSize?: number,
    dayNameFontSize?: number
}

export default function AnniversaryBlockList(props: Props) {
    return (
        <Box
            sx={{
                background: props.backgroundColor || "white",
                width: props.size,
                borderRadius: props.borderRadius ? `${props.borderRadius}px` : undefined,
                color: props.fontColor,
                padding: (props.size || 160) / 10 + (props.borderRadius || 0) + "px",
            }}
        >
            {
                props.items.map((item, index) => (
                    <>
                        <AnniversaryBlock
                            key={item.id}
                            item={item}
                            borderRadius={props.borderRadius}
                            backgroundColor={props.backgroundColor}
                            fontColor={props.fontColor}
                            dayCountFontSize={props.dayCountFontSize}
                            dayNameFontSize={props.dayNameFontSize}
                        />

                        {index < props.items.length - 1 && (
                            <hr style={{borderTop: "dotted 1px"}}/>
                        )}
                    </>
                ))
            }
        </Box>
    )
}
