import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import ResizableImage from "../ResizableMedia/ResizableImage";
import {useWindowSize} from "../../customHooks";

type Props = {
    imageUrl?: string
}

export default function PhotoStyle1(props: Props) {
    const containerRef = useRef<HTMLDivElement>();
    const [windowWidth, windowHeight] = useWindowSize();
    const [imageWidth, setImageWidth] = useState<undefined | number>(undefined);
    const [imageHeight, setImageHeight] = useState<undefined | number>(undefined);

    useEffect(() => {
        if (containerRef.current) {
            onResize(containerRef.current.offsetWidth, containerRef.current.offsetHeight)
        }
    }, [windowWidth, windowHeight]);

    const onResize = (width: number, height: number) => {
        setImageWidth(width);
        setImageHeight(height);
    }

    return (
        <Box
            ref={(element: HTMLDivElement) => {
                containerRef.current = element;
                if (element) {
                    onResize(element.offsetWidth, element.offsetHeight)
                }
            }}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%"
            }}
        >
            <Box
                sx={{
                    position: "relative",
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        width: imageWidth ? imageWidth + 32 : 0,
                        height: imageHeight ? imageHeight + 32 : 0,
                        background: "#053d54",
                        transform: "rotate(-5deg)",
                        zIndex: 0,
                        transition: "width 0.2s, height 0.2s"
                    }}
                />

                <Box
                    sx={{
                        boxShadow: theme => theme.shadows[5]
                    }}
                    style={{
                        position: "relative",
                        border: "solid 16px white",
                        backgroundColor: "white",
                        width: imageWidth ? imageWidth + 32 : 0,
                        height: imageHeight ? imageHeight + 32 : 0,
                        zIndex: 0,
                        transition: "width 0.2s, height 0.2s"
                    }}
                >
                    {
                        imageWidth && imageHeight && (
                            <ResizableImage
                                containerWidth={imageWidth}
                                containerHeight={imageHeight}
                                imageUrl={props.imageUrl}
                                onResized={onResize}
                            />
                        )
                    }
                </Box>
            </Box>

        </Box>
    )
}
