import React, {ReactNode, useContext, useEffect, useState} from "react";
import LyricsPlayer from "./LyricsPlayer";
import {CoupleSummaryContext} from "../../context/CoupleSummaryContextProvider";
import {PlaylistItem} from "../../data/info";
import Api from "../../bundle/Api";

type Props = {
    children: ReactNode
}

export default function PlaylistLyricsPlayer(props: Props) {
    const [playlist, setPlaylist] = useState<undefined | PlaylistItem[]>(undefined);
    const [playingIndex, setPlayingIndex] = useState(0);

    const nextMusic = () => {
        if (!playlist) return;

        if (playingIndex === playlist.length - 1) {
            setPlayingIndex(0);
        } else {
            setPlayingIndex(playingIndex + 1);
        }
    }

    const prevMusic = () => {
        if (!playlist) return;

        if (playingIndex === 0) {
            setPlayingIndex(playlist.length - 1);
        } else {
            setPlayingIndex(playingIndex - 1);
        }
    }

    useEffect(() => {
        Api.adapters.info.getPlaylist()
            .then(apiResponse => {
                if (apiResponse.status === 200) {
                    setPlaylist(apiResponse.data);
                    setPlayingIndex(0);
                }
            })
    }, []);

    return (
        <LyricsPlayer
            currentMusic={playlist && playlist[playingIndex] ? playlist[playingIndex].media : undefined}
            nextMusic={nextMusic}
            prevMusic={prevMusic}
        >
            {props.children}
        </LyricsPlayer>
    )
}
