// @flow

import _ from "lodash";
import {replaceAllText} from "../util/stringUtils";

export default class UrlBuilder {
    private readonly url: string;
    private substitutions: { key: string, value: string }[] | undefined;
    private query: { [key: string]: string | undefined } | undefined;
    private hash: string | undefined;

    constructor(url: string) {
        this.url = url;
        this.substitutions = undefined;
        this.query = undefined;
        this.hash = undefined;
    }

    withSubstitutions(substitutions: Array<{ key: string, value: string }>): UrlBuilder {
        this.substitutions = substitutions;
        return this;
    }

    withQuery(query: { [key: string]: string | undefined }): UrlBuilder {
        this.query = query;
        return this;
    }

    withHash(hash: string): UrlBuilder {
        this.hash = hash;
        return this;
    }

    build(): string {
        let newUrl = this.substitutions
            ? replaceAllText(this.url, this.substitutions)
            : this.url;
        let queryDataString = '';
        if (this.query) {
            let queryList: string[] = [];
            _.keys(this.query).forEach(key => {
                if (!key || !this.query![key]) return;

                queryList.push(
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(this.query![key]!),
                );
            });
            queryDataString += queryList.join('&');
        }
        if (this.hash) {
            newUrl += "#" + this.hash;
        }
        newUrl += queryDataString ? '?' + queryDataString : '';

        return newUrl || '/';
    }
}
