import {Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

type Props = {
    size: number,
    onClose?: () => void
}

export default function CircleCloseButton(props: Props) {
    return (
        <Box
            sx={{
                width: (props.size + 4) + "px",
                height: (props.size + 4) + "px",
                background: "#FFFFFFAA",
                borderRadius: ((props.size + 4) / 2) + "px",
                boxShadow: theme => theme.shadows[5],
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "background 0.2s, width 0.2s, height 0.2s, transform 0.2s",
                "&:hover": {
                    background: "white",
                    transform: "scale(1.1)",
                    "& svg": {
                        transform: "rotate(180deg)"
                    }
                }
            }}
            onClick={props.onClose}
        >
            <CloseIcon
                sx={{
                    fontSize: props.size,
                    transition: "transform 0.2s"
                }}
            />
        </Box>
    )
}
