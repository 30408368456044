import AddIcon from "@mui/icons-material/Add";
import {Box} from "@mui/material";
import React, {ReactNode} from "react";

type Props = {
    children: ReactNode,
    onClick?: () => void,
    height: number
};

export default function RoundGrayButton(props: Props) {
    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: `${props.height}px`,
                background: "#AAAAAA88",
                borderRadius: "16px",
                fontSize: "16px",
                margin: "8px 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                cursor: "pointer",
                transition: "transform 0.2s, background 0.2s",
                "&:hover": {
                    transform: "scale(1.03)",
                    border: "solid 4px #d4efff",
                    background: "#FFFFFF"
                }
            }}
            onClick={props.onClick}
        >
            {props.children}
        </Box>
    )
}
