import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {getResizedDimension} from "./index";
import RectangularLoadingSpinner from "../RectangularLoadingSpinner";
import {useWindowSize} from "../../customHooks";

type Props = {
    containerWidth: number,
    containerHeight: number,
    imageUrl?: string,
    onResized?: (width: number, height: number) => void
}

export default function ResizableImage(props: Props) {
    const img = useRef(new Image());
    const [imgWidth, setImgWidth] = useState<undefined | number>(undefined);
    const [imgHeight, setImgHeight] = useState<undefined | number>(undefined);
    const [imgActualWidth, setImgActualWidth] = useState<undefined | string>(undefined);
    const [imgActualHeight, setImgActualHeight] = useState<undefined | string>(undefined);

    const updateActualSize = (imgWidth: number, imgHeight: number) => {
        const [outputWidth, outputHeight] = getResizedDimension(props.containerWidth, props.containerHeight, imgWidth, imgHeight);
        setImgActualWidth(outputWidth + "px");
        setImgActualHeight(outputHeight + "px");
        props.onResized && props.onResized(outputWidth, outputHeight);
    }

    useEffect(() => {
        img.current.onload = function () {
            // @ts-ignore
            let width = this.width;
            // @ts-ignore
            let height = this.height;


            setImgWidth(width);
            setImgHeight(height);
            updateActualSize(width, height);
        }
    }, [])

    useEffect(() => {
        img.current.src = props.imageUrl || "";
    }, [props.imageUrl]);

    useEffect(() => {
        if (imgWidth !== undefined && imgHeight !== undefined) {
            updateActualSize(imgWidth, imgHeight);
        }
    }, [props.containerWidth, props.containerHeight]);

    if (imgActualWidth === undefined || imgActualHeight === undefined) {
        return <RectangularLoadingSpinner color="#d4efff"/>
    }

    return (
        <Box
            sx={{
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
            }}
            style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url('${props.imageUrl}')`,
            }}
        />
    )
}
