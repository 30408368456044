import {Box} from "@mui/material";
import React, {ReactElement} from "react";
import Tag from "./Tag";

type Props = {
    children: ReactElement<typeof Tag> | Array<ReactElement<typeof Tag>>;
}

export default function TagList(props: Props) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                "& .tag": {
                    marginRight: "8px"
                }
            }}
        >
            {props.children}
        </Box>
    )
}
