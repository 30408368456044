import Config from "../config/Config";
import InfoApiUrl from "../url/InfoApiUrl";
import InfoAdapter from "../adapter/InfoAdapter";
import DiaryApiUrl from "../url/DiaryApiUrl";
import DiaryAdapter from "../adapter/DiaryAdapter";

const Api = {
    servers: Config.servers,
    urls: {
        info: InfoApiUrl.urls,
        diary: DiaryApiUrl.urls,
    },
    urlBuilders: {
        info: InfoApiUrl.builders,
        diary: DiaryApiUrl.builders
    },
    adapters: {
        info: InfoAdapter,
        diary: DiaryAdapter
    }
}

export default Api;
