import {Box} from "@mui/material";
import React, {useContext, useEffect} from "react";
import DiaryPaper from "../DiaryPaper";
import DiaryEditor from "../DiaryEditor";
import {DiaryDetail} from "../../data/diary";
import {CoupleSummaryContext} from "../../context/CoupleSummaryContextProvider";
import {useSnackbar} from "notistack";

type Props = {
    isShow: boolean,
    modifyingDiary?: DiaryDetail,
    onClose: () => void,
    onCreatedDiary: (diary: DiaryDetail) => void
    onUpdatedDiary: (diary: DiaryDetail) => void
}

export default function DiaryEditorDialog(props: Props) {
    const coupleSummaryContext = useContext(CoupleSummaryContext);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (props.isShow && coupleSummaryContext?.data?.isDemo && !coupleSummaryContext.data.me.isAdmin) {
            enqueueSnackbar(
                '警告！你無法在演示模式中新增回憶。',
                {variant: "warning"}
            );
        }
    }, [props.isShow])

    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                pointerEvents: props.isShow ? undefined : "none",
                zIndex: 500
            }}
        >
            <Box
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    background: "#AAAAAA88",
                    opacity: props.isShow ? 1 : 0,
                    transition: "opacity 0.2s"
                }}
            />

            <Box
                sx={{
                    position: "absolute",
                    width: "80%",
                    maxWidth: "1800px",
                    height: "calc(100% - 120px)",
                    marginTop: "64px",
                    marginBottom: "120px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    top: props.isShow ? 0 : "100%",
                    transition: "top 0.5s"
                }}
            >
                {
                    props.isShow && (
                        <DiaryPaper
                            showCloseButton
                            onClose={props.onClose}
                        >
                            <DiaryEditor
                                modifyingDiary={props.modifyingDiary}
                                onCreated={diary => {
                                    props.onClose();
                                    props.onCreatedDiary(diary);
                                }}
                                onUpdated={diary => {
                                    props.onClose();
                                    props.onUpdatedDiary(diary);
                                }}
                            />
                        </DiaryPaper>
                    )
                }
            </Box>
        </Box>
    )
}
