import AddIcon from "@mui/icons-material/Add";
import React, {useMemo} from "react";
import {getUniqueId} from "../../../util/stringUtils";
import MediaUploaderItemContainer from "./MediaUploaderItemContainer";

type Props = {
    onSelectedFiles: (file: FileList) => void,
    supportedMimeTypes: string[]
};

export default function AddMediaItem(props: Props) {
    const uploadButtonId = useMemo(() => "diary_media_upload_" + getUniqueId(), []);

    return (
        <MediaUploaderItemContainer isButton>
            <label
                htmlFor={uploadButtonId}
                style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer"
                }}
            >
                <AddIcon/>
            </label>
            <input
                id={uploadButtonId}
                type="file"
                hidden
                accept=".jpg,.png,.gif,.mp3,.ogg,.wav,.mp4,.mpeg,.3gp,.webm"
                onClick={(e) => {
                    (e.target as HTMLInputElement).value = "";
                }}
                onChange={(e) => {
                    if (e.target.files !== null) {
                        props.onSelectedFiles(e.target.files);
                    }
                }}
            />
        </MediaUploaderItemContainer>
    )
}
