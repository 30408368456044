import React, {ReactNode, useEffect, useMemo, useState} from "react";
import Api from "../bundle/Api";
import {CoupleSummary, CoupleSummaryContextData} from "../data/info";
import CoupleSummaryLoadingOverlay from "../components/CoupleSummaryLoadingOverlay";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import AppPath from "../bundle/AppPath";
import {usePrevious} from "../customHooks";
import qs from "qs";
import MessagingService from "../service/MessagingService";
import {Box} from "@mui/material";

type Props = {
    children: ReactNode
}

export const CoupleSummaryContext = React.createContext<undefined | CoupleSummaryContextData>(undefined);

export default function CoupleSummaryContextProvider(props: Props) {
    const [summary, setSummary] = useState<CoupleSummary | undefined>(undefined);
    const [isLoadingSummary, setIsLoadingSummary] = useState(false);
    const [isShowAcceptNotificationInfoBox, setIsShowAcceptNotificationInfoBox] = useState(false);
    const [isShowLoadingOverlay, setIsShowLoadingOverlay] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const prevPathname = usePrevious(location.pathname);

    const loadSummary = () => {
        console.log("loadSummary");
        setIsLoadingSummary(true);
        setIsShowLoadingOverlay(true);

        if (Notification.permission === "default") {
            setIsShowAcceptNotificationInfoBox(true);
        }

        const task = async () => {
            const messagingToken = await MessagingService.getToken();

            setIsShowAcceptNotificationInfoBox(false);

            const apiResponse = await Api.adapters.info.getSummary(messagingToken);
            if (apiResponse.status === 200) {
                setSummary(apiResponse.data);
                setIsLoadingSummary(false);

                const query = qs.parse(location.search, { ignoreQueryPrefix: true });

                if (query.from) {
                    navigate(query.from as string);
                } else {
                    navigate(AppPath.diaryListing);
                }
            }
        }
        task();
    }


    if (location.pathname !== AppPath.landing && !summary) {
        return (
            <Navigate to={`${AppPath.landing}?from=${location.pathname}`} replace />
        )

        // navigate(`${AppPath.landing}?from=${location.pathname}`, {replace: true});
        // return <div/>;
    }

    return (
        <CoupleSummaryContext.Provider
            value={{
                data: summary,
                api: {loadSummary}
            }}
        >
            {props.children}

            {
                isShowLoadingOverlay && (
                    <CoupleSummaryLoadingOverlay
                        canFadeOut={!!summary}
                        onFadeOutCompleted={() => {
                            setIsShowLoadingOverlay(false);
                        }}
                    />
                )
            }

            {
                isShowAcceptNotificationInfoBox && (
                    <Box
                        id="allowNotificationInfo"
                        sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            zIndex: 1000
                        }}
                    >
                        <Box
                            sx={{
                                background: "black",
                                borderRadius: "16px",
                                margin: "16px",
                                padding: "16px",
                                fontSize: "18px",
                                textAlign: "center"
                            }}
                        >
                            如果你的畫面彈出了要求通知權限的視窗，請提供永久的權限以正常接收通知~
                        </Box>
                    </Box>
                )
            }

        </CoupleSummaryContext.Provider>
    )
}
