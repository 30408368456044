import {AudioApi, LyricItem} from "../../../data/lyricsPlayer";
import CollapsedLyrics from "./CollapsedLyrics";
import React from "react";
import PlayerControl from "./PlayerControl";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

type Props = {
    id?: string,
    title?: string,
    artist?: string,
    albumImgUrl?: string,
    lyrics: LyricItem[] | undefined,
    currentLyricIndex: number | undefined,
    audioApi: AudioApi,
    isPlaying: boolean,
    nextMusic: () => void,
    prevMusic: () => void,
    toggleExpendLyrics: () => void
}

export default function PlayerPanel(props: Props) {
    const theme = useTheme();
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));
    const matchesXXl = useMediaQuery("(min-width:2285px)");
    return (
        <Box
            id="playerPanel"
        >
            <Box
                sx={{
                    position: "absolute",
                    left: "-20px",
                    bottom: {
                        xs: "-210px",
                        md: matchesXXl ? "-180px" : matchesXl ? "-200px" : "-180px"
                    },
                    width: "calc(100% + 50px)",
                    height: "300px",
                    padding: "32px 40px",
                    backgroundColor: "rgba(178,238,255,1)",
                    transformOrigin: "top left",
                    transform: matchesXXl ? "rotate(-4deg)" : "rotate(-5deg)",
                    overflow: "hidden",
                    zIndex: 1000
                }}
            />
            <Box
                sx={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "space-between",
                    left: "-20px",
                    bottom: {
                        xs: "-210px",
                        md: "-160px"
                    },
                    width: "calc(100% + 50px)",
                    height: "300px",
                    padding: {
                        xs: "24px 16px",
                        md: "32px 40px"
                    },
                    backgroundColor: "#FFFFFF99",
                    transformOrigin: "top left",
                    transform: "rotate(-3deg)",
                    overflow: "hidden",
                    zIndex: 1000
                }}
            >

                <PlayerControl
                    id={props.id}
                    title={props.title}
                    artist={props.artist}
                    albumImgUrl={props.albumImgUrl}
                    audioApi={props.audioApi}
                    isPlaying={props.isPlaying}
                    nextMusic={props.nextMusic}
                    prevMusic={props.prevMusic}
                />

                {
                    props.lyrics !== undefined && props.currentLyricIndex !== undefined && (
                        <CollapsedLyrics lyrics={props.lyrics} currentLyricIndex={props.currentLyricIndex}/>
                    )
                }

                <Box
                    sx={{
                        position: "absolute",
                        top: "16px",
                        right: "40px",
                        color: "#b0b0b0",
                        cursor: "pointer",
                        ":hover": {
                            color: "#000",
                            fontSize: "22px"
                        }
                    }}
                    onClick={props.toggleExpendLyrics}
                >
                    <OpenInFullIcon/>
                </Box>

            </Box>
        </Box>
    )
}
