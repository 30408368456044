import {Box} from "@mui/material";
import {MediaInfo} from "../../data/media";
import MediaCarousel from "../MediaCarousel";
import React from "react";
import CircleCloseButton from "../CircleCloseButton";

type Props = {
    medias: MediaInfo[],
    defaultShowIndex: number,
    onClose: () => void
}

export default function ExpendedGalleryOverlay(props: Props) {
    return (
        <Box
            sx={{
                background: "#000000EE",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 6000
            }}
        >
            <MediaCarousel isShowExpendButton={false} medias={props.medias} defaultShowIndex={props.defaultShowIndex}/>
            <Box
                sx={{
                    position: "absolute",
                    right: "16px",
                    top: "16px"
                }}
            >
                <CircleCloseButton
                    size={36}
                    onClose={props.onClose}
                />
            </Box>
        </Box>
    )
}
