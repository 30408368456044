import {useSnackbar} from "notistack";
import React, {ReactNode, useEffect, useRef} from "react";
import MessagingService from "../../service/MessagingService";
import {Unsubscribe} from "firebase/messaging";

type Props = {
    children: ReactNode
}

export default function MessagingHandler(props: Props) {
    const {enqueueSnackbar} = useSnackbar();
    const messageListenerUnsubscribeRef = useRef<undefined | Unsubscribe>();

    useEffect(() => {
        messageListenerUnsubscribeRef.current = MessagingService.newMessageListener((payload) => {
            if (payload.notification) {
                let content = "";
                if (payload.notification.title) content += payload.notification.title + ": ";
                content += payload.notification.body;
                enqueueSnackbar(content);
            }
        });
    }, []);


    return <>{props.children}</>;
}
