import {Box, Card, CardContent, IconButton, ImageList, ImageListItem} from "@mui/material";
import {MediaInfo} from "../../data/media";
import React, {ReactElement} from "react";
import {getMimeMainType} from "../../util/mediaUtils";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';

type Props = {
    medias: MediaInfo[],
    onClick?: (index: number) => void
}

const getGalleryItems = (media: MediaInfo[], onClick?: (index: number) => void) => {
    const items: ReactElement[] = [];
    for (let i=0; i<media.length; i++) {
        const current = media[i];
        if (getMimeMainType(current.mime) === "image") {
            items.push(
                <Box
                    sx={{
                        position: "relative",
                        width: "100%",
                        borderRadius: "8px"
                    }}
                >
                    <img
                        key={current.id}
                        src={current.url}
                        style={{
                            width: "100%",
                            borderRadius: "8px"
                        }}
                    />
                    <Box
                        sx={{
                            position: "absolute",
                            top: "8px",
                            right: "8px"
                        }}
                    >
                        <IconButton
                            aria-label="Zoom"
                            size="large"
                            color="primary"
                            onClick={() => onClick && onClick(i)}
                        >
                            <ZoomOutMapIcon fontSize="inherit"/>
                        </IconButton>
                    </Box>
                </Box>
            );
        } else if (getMimeMainType(current.mime) === "video") {
            items.push(
                <video
                    controls
                    key={current.id}
                    src={current.url}
                    style={{
                        width: "100%",
                        borderRadius: "8px"
                    }}
                />
            );
        } else if (getMimeMainType(current.mime) === "audio") {
            items.push(
                <audio
                    controls
                    key={current.id}
                    src={current.url}
                    style={{
                        width: "80%",
                        textAlign: "center"
                    }}
                />
            );
        }
    }
    return items;
}

export default function DiaryDetailGallery(props: Props) {
    if (!props.medias || props.medias.length === 0) {
        return null;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                "& > *": {
                    margin: "16px 0"
                }
            }}
        >
            {
                getGalleryItems(props.medias, props.onClick)
            }
        </Box>
    );
}
