import {Box, Grid} from "@mui/material";
import React, {ReactNode, useContext, useEffect, useRef, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import AddMediaItem from "./AddMediaItem";
import SupportedMimeTypes from "../../../bundle/SupportedMimeTypes";
import {getMimeType} from "../../../util/fileUtils";
import {useSnackbar} from "notistack";
import DiaryMediaUploadService, {MediaStatus, MediaUploadId} from "../../../service/DiaryMediaUploadService";
import MediaItem from "./MediaItem";
import _ from "lodash";
import Api from "../../../bundle/Api";
import {MediaInfo} from "../../../data/media";
import {CoupleSummaryContext} from "../../../context/CoupleSummaryContextProvider";

export type UploadMedia = {
    id: string,
    status: "pending" | "uploading" | "success" | "failed" | "cancelled",
    media?: MediaInfo
}

type Props = {
    isImmediateRemoveRemote: boolean,
    defaultMedia?: MediaInfo[]
    onUpdateMedia: (uploadMedia: UploadMedia[]) => void
}

const supportedMimeTypes = [...SupportedMimeTypes.audio, ...SupportedMimeTypes.image, ...SupportedMimeTypes.video];

const mediaItemContainer = (children: ReactNode) => (
    <Grid item xs={12} sm={6} xl={4}>
        {children}
    </Grid>
);

export default function MediaUploader(props: Props) {
    const coupleSummaryContext = useContext(CoupleSummaryContext);

    let onMediaStatusUpdate: (mediaStatus: MediaStatus) => void;
    const getDefaultMediaState = (defaultMedia?: MediaInfo[]) => {
        if (!defaultMedia) return {};
        const media: { [key: MediaUploadId]: MediaStatus } = {};
        for(let item of defaultMedia) {
            media[item.id] = {
                id: item.id,
                file: null,
                uploadStatus: "success",
                statusUpdateCallback: onMediaStatusUpdate,
                response: item
            }
        }
        return media;
    };

    const getDefaultMediaSequence = (defaultMedia?: MediaInfo[]) => {
        if (!defaultMedia) return [];

        return defaultMedia.map(item => item.id);
    }

    const {enqueueSnackbar} = useSnackbar();
    const [media, setMedia] = useState<{ [key: MediaUploadId]: MediaStatus }>(getDefaultMediaState(props.defaultMedia));
    const mediaSequenceRef = useRef<MediaUploadId[]>(getDefaultMediaSequence(props.defaultMedia));

    const removeMediaRecord = (mediaUploadId: MediaUploadId) => {
        const removedMedia = {...media};
        delete removedMedia[mediaUploadId];
        setMedia(removedMedia);
        _.remove(mediaSequenceRef.current, mediaUploadId);
    }

    onMediaStatusUpdate = (mediaStatus: MediaStatus) => {
        setMedia(prevState => ({...prevState, [mediaStatus.id]: mediaStatus}));
        if (mediaStatus.uploadStatus === "success") {
            enqueueSnackbar(
                `上載完成! (文件: ${mediaStatus.file!.name})`,
                {variant: "success"}
            );
        } else if (mediaStatus.uploadStatus === "failed") {
            enqueueSnackbar(
                `上載失敗! (文件: ${mediaStatus.file!.name})`,
                {variant: "error"}
            );
            removeMediaRecord(mediaStatus.id);
        } else if (mediaStatus.uploadStatus === "cancelled") {
            removeMediaRecord(mediaStatus.id);
        }
    }

    const onSelectedFiles = (files: FileList) => {
        try {
            for (let i = 0; i < files.length; i++) {
                if (SupportedMimeTypes.checkIsSupport(getMimeType(files[i]), supportedMimeTypes)) {
                    const mediaStatus = DiaryMediaUploadService.append(files[i], onMediaStatusUpdate);
                    setMedia(prevState => ({...prevState, [mediaStatus.id]: mediaStatus}));
                    mediaSequenceRef.current.push(mediaStatus.id);
                } else {
                    enqueueSnackbar(
                        `不支援「${files[i].name}」的文件格式，已被無視~~`,
                        {
                            autoHideDuration: 8000,
                            variant: "warning"
                        }
                    );
                }
            }
        } catch (e) {
            enqueueSnackbar("你使用的瀏覽器不支援檢查文件格式，請更換瀏覽器再試。");
        }
    }

    const onRemoveMedia = (mediaUploadId: MediaUploadId) => {
        const removingMedia = media[mediaUploadId];
        const isCancelled = DiaryMediaUploadService.cancel(mediaUploadId);
        if (!isCancelled) {
            if (removingMedia.uploadStatus === "success" && removingMedia.response && props.isImmediateRemoveRemote) {
                Api.adapters.diary.removeCreateDiaryMedia((removingMedia.response as MediaInfo).id)
                    .then(_ => {
                        removeMediaRecord(mediaUploadId);
                    });
            } else {
                removeMediaRecord(mediaUploadId);
            }
        }
    }

    useEffect(() => {
        const uploadMedia: UploadMedia[] = [];
        for (let mediaUploadId of mediaSequenceRef.current) {
            const current = media[mediaUploadId];
            if (!current) continue;
            uploadMedia.push({
                id: current.id,
                status: current.uploadStatus,
                media: current.response as MediaInfo
            });
        }
        props.onUpdateMedia(uploadMedia);
    }, [media]);

    return (
        <Box
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                border: "solid 1px #BBBBBB",
                display: "flex",
                flexDirection: "column"
            }}
            onDragOver={(e) => {
                e.preventDefault();
            }}
            onDrop={(e) => {
                e.preventDefault();
                onSelectedFiles(e.dataTransfer.files);
            }}
        >

            <h2 style={{padding: "0 16px", fontFamily: "SetoFontSP, Roboto, sans-serif"}}>回憶點滴</h2>
            <PerfectScrollbar
                style={{
                    height: "100%"
                }}
                option={{suppressScrollX: true}}
            >
                {
                    (coupleSummaryContext?.data?.isDemo && !coupleSummaryContext.data.me.isAdmin) ? (
                        <div style={{marginLeft: "16px"}}>你無法在演示模式中上載回憶點滴</div>
                    ) : (
                        <Grid container spacing={2} style={{padding: "0 16px 16px 16px"}}>
                            {
                                mediaItemContainer(
                                    <AddMediaItem
                                        onSelectedFiles={onSelectedFiles}
                                        supportedMimeTypes={supportedMimeTypes}
                                    />
                                )
                            }

                            {
                                mediaSequenceRef.current.map(mediaId => {
                                    const mediaItem = media[mediaId];
                                    if (mediaItem) {
                                        return mediaItemContainer(
                                            <MediaItem
                                                key={mediaItem.id}
                                                mediaStatus={mediaItem}
                                                onRemove={() => onRemoveMedia(mediaId)}
                                            />
                                        )
                                    } else {
                                        return undefined;
                                    }
                                })
                            }
                        </Grid>
                    )
                }
            </PerfectScrollbar>
        </Box>
    )
}
