import {HashRouter, Route, Routes} from "react-router-dom";
import AppPath from "./bundle/AppPath";
import LoginPage from "./page/LoginPage";
import MainPage from "./page/MainPage";
import React from "react";

export default function SiteRouter() {
    return (
        <Routes>
            <Route path={AppPath.landing} element={<LoginPage/>}/>
            <Route path={AppPath.diaryListing} element={<MainPage/>}/>
            <Route path={AppPath.diaryDetail} element={<MainPage/>}/>
        </Routes>
    );
}
