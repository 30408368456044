import React, {useContext, useMemo} from "react";
import {Box, keyframes, styled} from "@mui/material";
import {ReactComponent as Heart} from "../../../res/img/heart.svg";
import {LyricsPlayerContext} from "../../../components/PlaylistLyricsPlayer/LyricsPlayer";

export default function BeatingHearts() {
    const lyricPlayerInfo = useContext(LyricsPlayerContext);

    const blueHeartbeats = keyframes`
        to { transform: scale(1) rotate(-10deg); }
    `;

    const whiteHeartbeats = keyframes`
        to { transform: scale(0.8) rotate(10deg); }
    `;

    const heartStyle = (color: "blue" | "white") => {
        if (lyricPlayerInfo.isPlaying && lyricPlayerInfo.estimateData?.bpm && lyricPlayerInfo.estimateData?.offset) {
            return {
                animation: `${color === "blue" ? blueHeartbeats : whiteHeartbeats} ${60 / lyricPlayerInfo.estimateData.bpm}s infinite alternate`,
                animationDelay: `${lyricPlayerInfo.estimateData?.offset}s`,
            }
        }
        return undefined;
    }


    const BlueHeart = useMemo(() => styled(Heart)({
        transform: "scale(0.8) rotate(-20deg)",
        transformOrigin: "bottom",
        transition: "transform 0.5s",
        ...heartStyle("blue")
    }), [lyricPlayerInfo.isPlaying, lyricPlayerInfo.estimateData?.bpm, lyricPlayerInfo.estimateData?.offset]);

    const WhiteHeart = useMemo(() => styled(Heart)({
        position: "absolute",
        left: "30px",
        transform: "scale(0.6) rotate(20deg)",
        transformOrigin: "bottom",
        transition: "transform 0.5s",
        ...heartStyle("white")
    }), [lyricPlayerInfo.isPlaying, lyricPlayerInfo.estimateData?.bpm, lyricPlayerInfo.estimateData?.offset]);

    return (
        <>
            <BlueHeart fill="#d4efff"/>
            <WhiteHeart fill="#FFF"/>
        </>
    )
}
