import React, {useContext, useEffect} from "react";
import {ColorModeContext} from "../../App";
import DiaryPageContainer from "./DiaryPageContainer";
import {Box} from "@mui/material";
import Page from "../../components/Page";
import BeatingHearts from "./DiaryPageContainer/BeatingHearts";
import AnniversaryBlock from "../../components/AnniversaryBlockList/AnniversaryBlock";
import AnniversaryBlockList from "../../components/AnniversaryBlockList";
import PerfectScrollbar from "react-perfect-scrollbar";
import {CoupleSummaryContext} from "../../context/CoupleSummaryContextProvider";
import PlaylistLyricsPlayer from "../../components/PlaylistLyricsPlayer";

export default function MainPage() {
    const colorMode = useContext(ColorModeContext);

    const summary = useContext(CoupleSummaryContext);
    const summaryData = summary!.data!;

    useEffect(() => {
        colorMode.setColorMode("light");
    }, []);

    const sideWidth = 168;
    return (
        <PlaylistLyricsPlayer>
            <Page
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"
                }}
            >
                <Box
                    sx={{
                        width: `${sideWidth}px`,
                        height: "100%",
                        display: {
                            xs: "none",
                            lg: "block"
                        },
                    }}
                >
                    {
                        summaryData.anniversaryDates.length > 0 && (
                            <AnniversaryBlock
                                item={summaryData.anniversaryDates[0]}
                                size={sideWidth}
                                showInnerBorder
                                backgroundColor="#053d54"
                                dayCountFontSize={28}
                                fontColor="white"
                            />
                        )
                    }

                    {
                        summaryData.anniversaryDates.length > 1 && (
                            <Box
                                sx={{
                                    position: "relative",
                                    width: "100%",
                                    height: `calc(100% - ${sideWidth}px)`,
                                    fontSize: "14px",
                                    ":hover": {
                                        zIndex: 2
                                    }
                                }}
                            >
                                <PerfectScrollbar option={{suppressScrollX: true}}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            top: "-8px",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <AnniversaryBlockList
                                            items={summaryData.anniversaryDates.slice(1)}
                                            size={sideWidth - 32}
                                            borderRadius={8}
                                            dayCountFontSize={24}
                                        />
                                    </Box>
                                </PerfectScrollbar>
                            </Box>
                        )
                    }

                </Box>

                <Box
                    sx={{
                        width: {
                            xs: "100%",
                            md: `calc(100% - ${sideWidth}px)`,
                        },
                        height: "100%",
                    }}
                >
                    <DiaryPageContainer/>
                </Box>

                <Box
                    sx={{
                        position: "absolute",
                        bottom: "32px",
                        left: "50px",
                        display: {
                            xs: "none",
                            lg: "block"
                        },
                    }}
                >
                    <BeatingHearts/>
                </Box>
            </Page>


        </PlaylistLyricsPlayer>
    );
}
