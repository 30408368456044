import MediaUploaderItemContainer from "./MediaUploaderItemContainer";
import React, {useEffect, useMemo, useState} from "react";
import DiaryMediaUploadService, {MediaStatus} from "../../../service/DiaryMediaUploadService";
import {Box, IconButton, LinearProgress} from "@mui/material";
import {getMimeType} from "../../../util/fileUtils";
import {getMimeMainType} from "../../../util/mediaUtils";
import DeleteIcon from '@mui/icons-material/Delete';
import styles from "./MediaItem.module.scss";

type Props = {
    mediaStatus: MediaStatus,
    onRemove: () => void
}

const mediaStyle = {
    width: "100%",
    height: "100%"
}

export default function MediaItem(props: Props) {
    const [progress, setProgress] = useState(0);

    const onUpdateProgress = (progressPercentage: number) => {
        setProgress(progressPercentage);
    }

    useEffect(() => {
        DiaryMediaUploadService.attachProgressCallback(props.mediaStatus.id, onUpdateProgress);
        return () => {
            DiaryMediaUploadService.removeProgressCallback(props.mediaStatus.id);
        }
    }, []);

    let progressVariant: "determinate" | "indeterminate" = "determinate";
    if (props.mediaStatus.uploadStatus === "pending") {
        progressVariant = "indeterminate"
    }

    let progressColor: "success" | "error" | undefined = undefined;
    if (props.mediaStatus.uploadStatus === "success") {
        progressColor = "success";
    } else if (props.mediaStatus.uploadStatus === "failed") {
        progressColor = "error";
    }

    const getMemeTypeFromMediaStatus = (mediaStatus: MediaStatus) => {
        if (props.mediaStatus.file) {
            return getMimeMainType(getMimeType(props.mediaStatus.file)).toLowerCase();
        } else if (props.mediaStatus.response) {
            // @ts-ignore
            return getMimeMainType(props.mediaStatus.response.mime);
        }
    }

    const media = useMemo(() => {
        if (getMemeTypeFromMediaStatus(props.mediaStatus) === "image") {

            return (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        // @ts-ignore
                        background: `url(${props.mediaStatus.file ? URL.createObjectURL(props.mediaStatus.file) : props.mediaStatus.response.url})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        borderRadius: "8px"
                    }}
                    className={styles.hoverPullIndex}
                />
            )
        } else if (getMemeTypeFromMediaStatus(props.mediaStatus) === "audio") {
            return (
                <audio
                    // @ts-ignore
                    src={props.mediaStatus.file ? URL.createObjectURL(props.mediaStatus.file) : props.mediaStatus.response.url}
                    controls
                    className={styles.hoverPullIndex}
                    style={{
                        width: "90%"
                    }}
                />
            );
        } else if (getMemeTypeFromMediaStatus(props.mediaStatus) === "video") {
            return (
                <video
                    // @ts-ignore
                    src={props.mediaStatus.file ? URL.createObjectURL(props.mediaStatus.file) : props.mediaStatus.response.url}
                    controls
                    className={styles.hoverPullIndex}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px"
                    }}
                />
            )
        }


        return undefined;
    }, [props.mediaStatus]);

    return (
        <MediaUploaderItemContainer
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#BBBBBB"
            }}
        >
            {
                media
            }

            <Box
                sx={{
                    position: "absolute",
                    top: "4px",
                    right: "4px",
                    color: "white"
                }}
            >
                <IconButton
                    aria-label="刪除"
                    color="inherit"
                    style={{backgroundColor: "#00000088", zIndex: 2}}
                    onClick={props.onRemove}
                >
                    <DeleteIcon/>
                </IconButton>
            </Box>

            {
                props.mediaStatus.file && (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            width: "100%",
                            padding: "8px",
                            background: "#00000088",
                            color: "white",
                            textAlign: "center",
                            fontSize: "12px",
                            borderRadius: "0 0 8px 8px"
                        }}
                    >
                        <LinearProgress
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%"
                            }}
                            variant={progressVariant}
                            color={progressColor}
                            value={progress}
                        />

                        {props.mediaStatus.file.name}
                    </Box>
                )
            }

        </MediaUploaderItemContainer>
    );
}
